import styled from 'styled-components/native';
import { normalizeLimit } from '../../utils/normalizeLimit';


export const Container = styled.View`
  margin: 0;
  flex: 1;
  background: #fff;
`;

export const MachineListContainer = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: false,
})`
  flex: 1;
`;

export const Header = styled.View`
  display: ${props => props.display ? 'flex' : 'none'};
  margin-top: 20px;
  padding: 0 20px;
`;

//TODO: padroniza tamanhos de títulos usando variável global
export const Title = styled.Text`
  font-size: ${normalizeLimit(17)}px;
  font-weight: bold
`;
