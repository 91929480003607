import React from "react";
import { LOCATION_IMAGE } from "../../image";
import { Container } from "./styles";

import { MachineIdentification } from "../../models/machine";

import MachineView from "../MachineView";
import Warning from "../Warning";

interface MachineListProps {
  machines: MachineIdentification[];
}

function MachineList({ machines }: MachineListProps) {
  return (
    <Container>
      {machines.length > 0 ? (
        machines?.map((machine) => (
          <MachineView key={machine.id} machine={machine} />
        ))
      ) : (
        <Warning
          imageSource={LOCATION_IMAGE}
          title="Máquinas não encontradas"
          message="Infelizmente não encontramos máquinas próximas a região em que você está."
        ></Warning>
      )}
    </Container>
  );
}

export default MachineList;
