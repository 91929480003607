import { MaterialIcons } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { ActionColor } from "../../utils/colors";

import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { Button } from "react-native-elements";
import {
  Item,
  Machine,
  MachineIdentification,
  ResponseMachineById,
} from "../../models/machine";
import { Offer } from "../../models/offer";
import { Product } from "../../models/product";
import api from "../../services/api";
import { formatCurrency } from "../../utils/formatNumber";
import {
  Container,
  Delivery,
  Details,
  DrinkHeader,
  DrinkItem,
  DrinkPrice,
  DrinkTitle,
  Info,
  ItemImage,
  ItemIngredient,
  ItemPrice,
  ItemTitle,
  LeftHeader,
  Message,
  OfferInfoGroup,
  OfferLocation,
  OfferMachineTittle,
  OfferRestriction,
  OldPrice,
  SubMessage,
  Warning,
  WarningText,
  Wrapper,
} from "./styles";

type RouteParamList = { offer: Offer };

// navigation prop
export default function OfferBasket() {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const navigation = useNavigation();
  const [itemOffer, setItemOffer] = useState<Offer>(route.params.offer);
  const [machine, setMachine] = useState<Machine | null>(null);
  const [loading, setLoading] = useState(false);
  const [basket, setBasket] = useState<Map<string, Item>>(
    new Map<string, Item>()
  );
  const [total, setTotal] = useState(0);
  const [optionalSelected, setOptionalSelected] = useState<Product | null>(
    null
  );

  useEffect(() => {
    async function getMachine() {
      try {
        setLoading(true);
        if (route.params.offer.machine_id) {
          const response = await api.get<ResponseMachineById>(
            `/machine/${route.params.offer.machine_id}`
          );
          setMachine(response.data.machine);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    getMachine();
  }, []);

  return (
    <Container>
      <Details>
        <ItemImage source={{ uri: itemOffer.photo }} />
        <ItemTitle>{itemOffer.title}</ItemTitle>
        <ItemIngredient>{itemOffer.description}</ItemIngredient>
        <Info>
          <ItemPrice>{formatCurrency(itemOffer.newPrice)}</ItemPrice>
          <OldPrice>{formatCurrency(itemOffer.price)}</OldPrice>
        </Info>
        <Delivery>
          <Wrapper>
            <MaterialIcons
              name={itemOffer.icon}
              size={22}
              color={ActionColor}
            />
            <OfferInfoGroup>
              <OfferMachineTittle>
                {machine?.name} a {machine?.distance}
              </OfferMachineTittle>
              <OfferLocation>
                {machine?.location.address}, {machine?.location.number}
              </OfferLocation>
              {machine?.restriction || itemOffer.restriction ? (
                <OfferRestriction>
                  {machine?.restriction} {itemOffer.restriction}
                </OfferRestriction>
              ) : (
                <OfferRestriction />
              )}
            </OfferInfoGroup>
          </Wrapper>
        </Delivery>
      </Details>

      <DrinkHeader>
        <LeftHeader>
          <Message>Escolha sua bebida</Message>
          <SubMessage>Escolha 1 opção</SubMessage>
        </LeftHeader>
        <Warning>
          <WarningText>OPCIONAL</WarningText>
        </Warning>
      </DrinkHeader>
      {machine?.products
        .filter(
          (item) =>
            item.product &&
            itemOffer.optional_products.indexOf(item.product.id) != -1 &&
            item.quantity > 0
        )
        .map((item) => (
          <DrinkItem
            key={item.product.id}
            onPress={() =>
              optionalSelected && optionalSelected.id === item.product.id
                ? setOptionalSelected(null)
                : setOptionalSelected(item.product)
            }
            style={{
              borderColor:
                optionalSelected && optionalSelected.id === item.product.id
                  ? ActionColor
                  : "rgba(0, 0, 0, 0.1)",
            }}
          >
            <LeftHeader>
              <DrinkTitle>{item.product.name}</DrinkTitle>
              <DrinkPrice>+ {formatCurrency(item.product.price)}</DrinkPrice>
            </LeftHeader>

            {optionalSelected && optionalSelected.id === item.product.id ? (
              <MaterialIcons name="done" size={17} color={ActionColor} />
            ) : (
              <DrinkTitle />
            )}
          </DrinkItem>
        ))}
      <Button
        title="Finalizar Compra"
        loading={loading}
        onPress={() => {
          //Decompondo Fat object to light interface
          //const {id, name, logo, location, star,categories, distance} = machine;
          //const mi: MachineIdentification = {id, name, logo, location, star,categories, distance};
          const offerItem: Item = {
            product: {
              id: itemOffer.product_id,
              name: itemOffer.title,
              price: itemOffer.newPrice,
            } as Product,
            quantity: 1,
          };
          setBasket(new Map<string, Item>());
          basket.set(itemOffer.product_id, offerItem);
          if (optionalSelected) {
            const optionalItem: Item = {
              product: optionalSelected,
              quantity: 1,
            };
            basket.set(optionalSelected.id, optionalItem);
          }
          const { id, name, logo, location, star, categories, distance } =
            machine;
          const mi: MachineIdentification = {
            id,
            name,
            logo,
            location,
            star,
            categories,
            distance,
          };

          navigation.navigate("Payment", { machine: mi, basket: basket });
        }}
      />
    </Container>
  );
}
