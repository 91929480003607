import styled from 'styled-components/native';
import { ActionColor } from '../../utils/colors';


export const Container = styled.View`
  background-color: white;
  padding: 0px 20px;
  flex: 1;
`;

export const ImageContainer = styled.View`
  opacity: 1;
  align-items: center;
`;

export const Name = styled.Text`
  margin-top: 16px;
  font-weight: 600;
  color: ${ActionColor};
  font-size: 14px;
  text-align: center;
`;

export const InputsContainer = styled.View`
  gap: 24px;
  flex: 1;
`;

export const InputMaskedContainer = styled.View`
  padding: 0px 10px;
`;

export const InputErrorMessage = styled.Text`
  color: rgb(255, 86, 101);
  margin: 5px;
  font-size: 13px;
`;