import { createContext, useContext, useState } from "react";

interface CartContextData {
  createCart(id: string, items): void;
  getCart(id: string): Cart | undefined;
  getCartItems(id: string): [];
  deleteCart(id: string): void;
  hasCart(id: string): boolean;
}

interface Cart {
  id: string;
  items: any;
}

type CartProviderProps = {
  children: React.ReactNode;
};
const CartContext = createContext<CartContextData>({} as CartContextData);

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [carts, setCarts] = useState<Cart[]>([{ id: "", items: [] }]);

  function createCart(id: string, items) {
    let updatedCart;
    if (hasCart(id)) {
      updatedCart = carts.map((cartItem) => {
        if (cartItem.id === id) {
          cartItem.items = items;
        }
        return cartItem;
      });
    } else {
      updatedCart = [...carts, { id, items }];
    }
    setCarts(updatedCart);
  }

  function hasCart(id: string) {
    return carts.some((cartItem) => cartItem.id === id);
  }

  function getCart(id: string) {
    return carts.find((cart) => cart.id === id && cart.items.length > 0);
  }

  function getCartItems(id: string) {
    const getCart = carts.find(
      (cart) => cart.id === id && cart.items.length > 0
    );
    if (getCart) return getCart.items;
    return [];
  }

  function deleteCart(id: string) {
    setCarts(carts.filter((cart) => cart.id !== id));
  }

  return (
    <CartContext.Provider
      value={{
        createCart,
        getCart,
        getCartItems,
        deleteCart,
        hasCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export function useCart() {
  const context = useContext(CartContext);
  return context;
}
