import React from 'react';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { ActionColor } from "../../utils/colors";
import { useAuth } from "../../contexts/auth";
import { formatCurrency } from '../../utils/formatNumber';

import
  {
    Container,
    BackButton,
    Finances,
    Title,
    Value,
    QrCode,
    Options,
    Option,
    Message,
    Panel,
    Money
  } from './styles';

export default function Wallet() {
  const { user } = useAuth();

  return (
    <Container>
      <Panel>
        <Finances>
          <Title>Seu saldo na carteira</Title>
          <Money>
            <Value>{ formatCurrency(user?.balance ? user?.balance : 0) }</Value>
            <MaterialIcons
              name="keyboard-arrow-right"
              size={20}
              color={ActionColor}
            />
          </Money>
        </Finances>

        <QrCode>
          <MaterialCommunityIcons
            name="qrcode-scan"
            color={ActionColor}
            size={28}
          />
          <Title>QR Code</Title>
        </QrCode>
      </Panel>

      <Options>
        <Option onPress={() => {}}>
          <MaterialIcons
            name="credit-card"
            size={35}
            color={ActionColor}
          />
          <Message>Formas de pagamento</Message>
        </Option>

        <Option onPress={() => {}}>
          <MaterialIcons
            name="card-giftcard"
            size={35}
            color={ActionColor}
          />
          <Message>Resgatar Cartão Pré-pago</Message>
        </Option>

        <Option onPress={() => {}}>
          <MaterialIcons
            name="store"
            size={35}
            color={ActionColor}
          />
          <Message>Por onde usar</Message>
        </Option>

        <Option onPress={() => {}}>
          <MaterialCommunityIcons
            name="help-circle"
            size={35}
            color={ActionColor}
          />
          <Message>Preciso de Ajuda</Message>
        </Option>
      </Options>
    </Container>
  );
}
