import styled from 'styled-components/native';
import { ActionColor } from '../../utils/colors';

export const Container = styled.View`
  margin: 0px 0px 0px 0px;
  background: #FFFFFF;
  padding: 15px 15px 0px 15px;
  flex: 1;
`;

export const Title = styled.Text`
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  color: ${ActionColor};
  margin: 5px 0px 5px 0px;
`;

export const AddressField = styled.Text`
  font-size: 17px;
  font-weight: normal;
  text-align: left;
  color: #777777;
  margin: 5px 0px 5px 5px;
  flex:1;
  text-align: left;
  alignSelf: stretch;
`;

export const AddressInfo = styled.View`
  flex-direction: column;
  align-items: center;
`;
//  margin: 0px 10px 0px 10px;

export const AddressInfoGroup = styled.View`
  flex-direction: row;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 0px;
  justify-content: space-between;
`;