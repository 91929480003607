import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { IMGMACHINE } from "../../image";
import { MachineIdentification } from "../../models/machine";
import { HomeRoutesStackTypes } from "../../routes/home.routes";
import { ActionColor } from "../../utils/colors";
import { formatDistance } from "../../utils/formatNumber";
import {
  CartContainer,
  CartNumber,
  Distance,
  MachineAddress,
  MachineContainer,
  MachineDescription,
  MachineImage,
  MachineInfo,
  MachineStar,
  MachineTitle,
} from "./styles";

interface MachineViewProps {
  machine: MachineIdentification;
}
export default function MachineView({ machine }: MachineViewProps) {
  const navigation = useNavigation<HomeRoutesStackTypes>();
  const { cart } = machine;
  const { id, status, name, address, logo, distance } = machine;

  function handlePressMachine() {
    machine.status == 1 &&
      navigation.navigate("MachineBasket", {
        machineId: machine.id,
        machine,
      });
  }

  function getTotal() {
    return cart.reduce((acc, cur) => {
      acc += cur.price * cur.quantity;
      return acc;
    }, 0);
  }

  function handleCart() {
    navigation.navigate("Payment", { machine, total: getTotal(), items: cart });
  }

  return (
    <MachineContainer status={status} key={id} onPress={handlePressMachine}>
      <MachineImage source={logo ? logo : IMGMACHINE} />
      <MachineInfo>
        <MachineDescription>
          <MachineTitle>{name}</MachineTitle>
        </MachineDescription>
        <MachineDescription>
          <MaterialIcons name="star" size={20} color="#FF7C01" />
          <MachineStar>{"Nova!"} </MachineStar>
          <Distance>
            {distance ? formatDistance(distance) : "distância desconhecida"}
          </Distance>
        </MachineDescription>
        <MachineDescription>
          <MachineAddress>{address}</MachineAddress>
        </MachineDescription>
      </MachineInfo>
      {cart.length >= 1 ? (
        <CartContainer onPress={handleCart}>
          <MaterialIcons name="shopping-cart" color={ActionColor} size={26} />
          <CartNumber>{cart.length}</CartNumber>
        </CartContainer>
      ) : null}
    </MachineContainer>
  );
}
