import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { Formik, FormikHelpers } from "formik";
import React, { useRef, useState } from "react";
import { Button, Input } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Yup from "yup";

import Link from "../../components/Link";
import { useAuth } from "../../contexts/auth";
import { AlertOS } from "../../hooks/AlertOS";
import { APPLOGO } from "../../image";
import { ActionColor } from "../../utils/colors";
import { dinamicPadding } from "../../utils/dinamicPaddingInput";
import {
  AppMessage,
  Container,
  LoginInfo,
  Logo,
  LogoContainer,
  Message,
  Title,
} from "./styles";

interface FormValues {
  email: string;
}

const ForgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("insira um email válido")
    .required("insira um email"),
});

const ForgotPassword: React.FC = () => {
  const navigation = useNavigation();
  const { forgotPassword } = useAuth();
  const [loading, setLoading] = useState(false);
  const inputEmailRef = useRef<typeof Input>(null);
  const initialValues: FormValues = {
    email: "",
  };

  let TITLE_ERROR = "Contate o suporte";
  let MESSAGE_ERROR =
    "Desculpe o transtorno. Entre em contato com o nosso suporte para mais informações";

  async function handleForgotPassword(
    formValues: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) {
    try {
      setLoading(true);
      const { email } = formValues;
      await forgotPassword(email);
      AlertOS({
        title: "Verifique seu email",
        message:
          "Para prosseguir, verifique o código que enviamos para o seu email.",
        toastType: "info",
      });
      setLoading(false);
      formikHelpers.resetForm();
      navigation.navigate("ForgotPasswordVerify", { email });
    } catch (err: any) {
      setLoading(false);
      console.log("Algo deu errado, tente novamente!", err);
      if (err.response) {
        TITLE_ERROR = err.response.data.title;
        MESSAGE_ERROR = err.response.data.message;
      }
      AlertOS({
        title: TITLE_ERROR,
        message: MESSAGE_ERROR,
        toastType: "error",
      });
    }
  }

  return (
    <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
      <Container>
        <LogoContainer>
          <Logo source={APPLOGO} />
        </LogoContainer>
        <AppMessage>
          <Title>Esqueceu a senha?</Title>
          <Message>
            Não se preocupe! Nós iremos lhe ajudar a recuperar a sua senha.
          </Message>
        </AppMessage>
        <Formik
          initialValues={initialValues}
          validationSchema={ForgotPasswordValidationSchema}
          validateOnChange={false}
          onSubmit={(values, formikHelpers) =>
            handleForgotPassword(values, formikHelpers)
          }
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <LoginInfo>
              <Input
                leftIcon={
                  <MaterialIcons name="email" size={24} color={ActionColor} />
                }
                leftIconContainerStyle={{
                  marginLeft: 0,
                  marginRight: 10,
                }}
                label="Email"
                placeholder="seu@mail.com"
                placeholderTextColor="#999"
                keyboardType="email-address"
                autoCapitalize="none"
                autoCorrect={false}
                returnKeyType="next"
                ref={inputEmailRef}
                blurOnSubmit={false}
                errorMessage={errors.email}
                value={values.email}
                onChangeText={handleChange("email")}
                onBlur={handleBlur("email")}
                containerStyle={{
                  paddingBottom: dinamicPadding(errors.email, touched.email),
                }}
              />

              <Button
                title="Enviar Código"
                loading={loading}
                onPress={() => handleSubmit()}
                style={{
                  flex: 1,
                }}
              />

              <Link
                text="Voltar"
                onPress={() => navigation.navigate("SignIn")}
              />
            </LoginInfo>
          )}
        </Formik>
      </Container>
    </KeyboardAwareScrollView>
  );
};

export default ForgotPassword;
