import styled from 'styled-components/native';


export const MachineSelected = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 0px;
`;

export const MachineImage = styled.Image`
  width: 60px;
  height: 60px;
  border-radius: 30px;
`;

export const MachineInfo = styled.View`
  display: flex;
  flex-shrink: 1;
  margin-left: 15px;
`;

export const MachineTitle = styled.Text`
  font-weight: bold;
  color: #333;
  margin-left: 0px;
  margin-right: 15px
`;

export const MachineDescription = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  align-items: center;
  flex-shrink:1;
`;

export const MachineStar = styled.Text`
  font-size: 14px;
  color: #FF7C01;
`;

export const Categories = styled.Text`
  font-size: 14px;
  color: #999;
`;

export const Distance = styled.Text`
  font-size: 14px;
  color: #999;
`;

export const MachineAddress = styled.Text`
  flex: 1;
  flex-shrink: 1;
  font-size: 14px;
  color: #999;
`;

