import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { Container, Button } from './styles';
import { ActionColor } from '../../../utils/colors';

// navigation prop
function MenuScan() {
  const navigation = useNavigation();
  return (
    <Container>
      {/* Navegar para 'Wallet' */}
      <Button onPress={() => navigation.navigate('Scan')}>
        <MaterialCommunityIcons
          name="qrcode-scan"
          color={ActionColor}
          size={25}
        />
      </Button>
    </Container>
  );
};

export default MenuScan;