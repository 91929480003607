import numeral from 'numeral';
import 'numeral/locales/pt-br';
import moment from 'moment';

numeral.locale('pt-br');

//Ver mais em http://numeraljs.com/#format
export const formatCurrency = (number: number) =>
  // numeral(number/100.00).format('$,0.00');  
  numeral(number/1.00).format('$,0.00');

export const formatPercent = (number: number) =>
  numeral(number/100.00).format('0,00 %');

export const formatDistance = (number: number) =>
   numeral(number).value().toPrecision(2) + ' km';

export const formatDate = (date: string) =>{
  const datepart = date.substr(0,10).split('-');
  const timepart = date.substr(11,8);
  const formated = datepart[2] + '/' + datepart[1] + '/' + datepart[0] + ' as ' + timepart;
  console.log('de:' + date + ' para: ' + formated);
  return formated;
};

export const formateDateTime = (date: string) => {
  const formattedDate = moment(date).format('lll');
  return formattedDate
}