import { MaterialIcons } from "@expo/vector-icons";
import { TouchableWithoutFeedback } from "react-native";
import { ActionColor } from "../../utils/colors";

function EyeIcon({ onChangeVisibility, visibility }: any) {
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        onChangeVisibility(!visibility);
      }}
    >
      <MaterialIcons
        name={visibility ? "visibility-off" : "visibility"}
        size={24}
        color={ActionColor}
      />
    </TouchableWithoutFeedback>
  );
}

export default EyeIcon;
