import { MaterialIcons } from "@expo/vector-icons";
import React from "react";

import { useNavigation } from "@react-navigation/native";
import { useAuth } from "../../../contexts/auth";
import {
  Avatar,
  Container,
  EditProfileBtn,
  Info,
  Name,
  ProfileButton,
  TextBtn,
} from "./styles";

function Person() {
  const { user } = useAuth();
  const navigation = useNavigation();

  let [firstName, lastName] = user.name.split(" ");
  user.name = `${firstName}${lastName ? " " + lastName : ""}`;

  function handleEditProfile() {
    navigation.navigate("EditProfile");
  }

  return (
    <Container>
      <ProfileButton>
        <Avatar source={{ uri: user?.photo }} />
        <Info>
          <Name>{user?.name}</Name>
          <EditProfileBtn onPress={() => handleEditProfile()}>
            <TextBtn>Editar perfil</TextBtn>
          </EditProfileBtn>
        </Info>

        <MaterialIcons name="keyboard-arrow-right" size={20} color="#999" />
      </ProfileButton>
    </Container>
  );
}

export default Person;
