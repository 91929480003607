import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import MenuLeft from "../components/Header/MenuLeft";
import { MachineIdentification } from "../models/machine";
import MachineBasket from "../pages/MachineBasket";
import OrdersTotem from "../pages/OrdersTotem";
import PaymentConfirmed from "../pages/PaymentConfirmed";
import PaymentTotem from "../pages/PaymentTotem";
import Pix from "../pages/Pix";
import PixError from "../pages/PixError";
import { normalizeLimit } from "../utils/normalizeLimit";
const Stack = createStackNavigator();

interface AppTotemRoutesProps {
  isTotemAccount?: boolean;
  machine?: MachineIdentification;
}

const TotemRoutes: React.FC<AppTotemRoutesProps> = ({ machine }) => (
  <NavigationContainer>
    <Stack.Navigator
      initialRouteName="MachineBasket"
      screenOptions={{ headerMode: "screen" }}
    >
      <Stack.Screen
        name="MachineBasket"
        component={MachineBasket}
        initialParams={{
          machineId: machine?.id,
          machine,
        }}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="OrdersTotem"
        component={OrdersTotem}
        options={{
          headerTitleStyle: { fontSize: normalizeLimit(15) },
          title: "Lista de pedidos",
          headerLeft: (props) => <MenuLeft />,
        }}
      />
      <Stack.Screen
        name="PaymentTotem"
        component={PaymentTotem}
        options={{
          headerTitleStyle: { fontSize: normalizeLimit(15) },
          title: "Seus produtos estarão em:",
          headerLeft: (props) => <MenuLeft />,
        }}
      />
      <Stack.Screen
        name="Pix"
        component={Pix}
        options={{
          headerTitleStyle: { fontSize: normalizeLimit(15) },
          title: "Pagamento Pix",
          headerLeft: (props) => <MenuLeft />,
        }}
      />
      <Stack.Screen
        name="PixError"
        component={PixError}
        options={{ headerShown: false }}
      />

      <Stack.Screen
        name="PaymentConfirmed"
        component={PaymentConfirmed}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  </NavigationContainer>
);

export default TotemRoutes;
