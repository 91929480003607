import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Platform, RefreshControl, ScrollView } from "react-native";
import { Button } from "react-native-elements";
import Link from "../../components/Link";
import Loading from "../../components/Loading";
import Warning from "../../components/Warning";
import { useAuth } from "../../contexts/auth";
import { AlertOS } from "../../hooks/AlertOS";
import { CARD_IMAGE, creditcardsimg } from "../../image";
import { Creditcard } from "../../models/creditcard";
import api from "../../services/api";
import { ActionColor, colorRed } from "../../utils/colors";
import { useCardsChangedListener } from "../CreditCard";
import {
  Container,
  CreditCardActions,
  CreditCardItem,
  CreditCardItemGroup,
  CreditCardList,
  CreditCardNew,
  ItemImage,
  ItemInfo,
  ItemTitle,
  Marker,
} from "./styles";

interface handleAlertMessageProps {
  title: string;
  message: string;
  toastType?: "info" | "success" | "warn" | "error";
}

function CreditCardManager() {
  const MSG_LOADING_CARD = "Buscando cartões...";
  const MSG_DELETING_CARD = "Apagando cartão...";
  const navigation = useNavigation();
  const { user, setContextUser } = useAuth();
  const [creditcards, setCreditCards] = useState<Creditcard[]>([]);
  const [cardSelected, setCardSelected] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [Refreshing, setRefreshing] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(MSG_LOADING_CARD);
  const [defaultCard, setDefaultCard] = useState<string>(
    user?.defaultCard ? user?.defaultCard : ""
  );
  const listRef = useRef<ScrollView>(null);

  function handleAlertMessages({
    title,
    message,
    toastType,
  }: handleAlertMessageProps) {
    setRefreshing(false);
    setLoading(false);
    AlertOS({
      title,
      message,
      toastType,
    });
  }

  function setLoadingAndRefreshing(isLoading: boolean) {
    setLoading(isLoading);
    setRefreshing(isLoading);
  }

  async function getCreditCards() {
    try {
      setLoadingAndRefreshing(true);
      const response = await api.get("/card");
      const { cards } = response.data;
      setLoadingAndRefreshing(false);
      return cards;
    } catch (err) {
      handleAlertMessages({
        title: "Erro na Busca de Cartões",
        message:
          "Ocorreu um erro na comunicação com o sistema. Contate o suporte",
        toastType: "error",
      });
    }
  }

  async function deleteCard(creditcard: Creditcard) {
    try {
      setLoadingMessage(MSG_DELETING_CARD);
      setLoadingAndRefreshing(true);
      await api.delete(`/card/${creditcard.card_id}`);
      await loadAndSetCreditCards();
      setLoadingAndRefreshing(false);
    } catch (err) {
      handleAlertMessages({
        title: "Lamentamos, não foi possível concluir a exclusão.",
        message:
          "Ocorreu um erro na comunicação com o sistema. Contate o suporte",
        toastType: "error",
      });
    }
  }

  async function updateDefaultCard(creditcard: Creditcard) {
    try {
      setLoading(true);
      await api.put(`card/default/${creditcard.card_id}`);
      setLoading(false);
    } catch (err) {
      handleAlertMessages({
        title: "Erro ao Definir Cartão Padrão",
        message:
          "Ops! Encontramos um problema ao tentar definir seu cartão como padrão",
        toastType: "error",
      });
    }
  }

  async function handleDefaultCard(creditcard: Creditcard) {
    setLoadingMessage("Aguarde um momento...");
    await updateDefaultCard(creditcard);
    await loadAndSetCreditCards();
    setLoadingMessage(MSG_LOADING_CARD);
    handleAlertMessages({
      title: "Cartão definido como Padrão",
      message:
        "Cartão definido com sucesso. Agora está pronto para ser utilizado",
      toastType: "success",
    });
  }

  async function handleDeleteCard(creditcard: Creditcard) {
    if (Platform.OS === "web") {
      const answer = confirm(
        `Deseja realmente excluir o cartão de final ${creditcard.final}?`
      );
      if (answer) {
        await deleteCard(creditcard);
      }
    } else {
      Alert.alert(
        "Excluir cartão",
        `Deseja realmente excluir o cartão de final ${creditcard.final}?`,
        [
          {
            text: "Sim, excluir o cartão",
            onPress: async () => {
              await deleteCard(creditcard);
            },
          },
          {
            text: "Não, manter o cartão",
            onPress: () => console.log("manter Pressed"),
            style: "cancel",
          },
        ],
        { cancelable: true }
      );
    }
  }

  async function loadAndSetCreditCards() {
    const cards = await getCreditCards();
    const updatedUser = { ...user };
    updatedUser.creditcards = cards;
    const defaultCard = cards.find((card: Creditcard) => card.default);
    updatedUser.defaultCard = defaultCard?.card_id ?? "";
    setCreditCards(cards);
    setDefaultCard(updatedUser.defaultCard);
    setContextUser(updatedUser);
  }

  useEffect(() => {
    loadAndSetCreditCards();
  }, []);

  useCardsChangedListener(({ cards: cards }) => {
    loadAndSetCreditCards();
  }, []);

  return loading ? (
    <Loading text={loadingMessage} />
  ) : (
    <Container>
      {creditcards.length > 0 ? (
        <CreditCardList
          ref={listRef}
          refreshControl={
            <RefreshControl
              refreshing={Refreshing}
              onRefresh={loadAndSetCreditCards}
            />
          }
        >
          {creditcards?.map((creditcard) => (
            <CreditCardItemGroup
              key={creditcard.card_id}
              style={{
                borderColor:
                  creditcard.card_id === cardSelected
                    ? ActionColor
                    : "rgba(0, 0, 0, 0.1)",
              }}
            >
              <CreditCardItem
                onPress={() => setCardSelected(creditcard.card_id)}
              >
                <ItemInfo>
                  <ItemImage source={creditcardsimg(creditcard.card_brand)} />
                  <ItemTitle>XXXX XXXX XXXX {creditcard.final}</ItemTitle>
                  <Marker>
                    {creditcard.card_id === cardSelected ? (
                      <MaterialIcons
                        name="keyboard-arrow-right"
                        size={17}
                        color={ActionColor}
                      />
                    ) : (
                      <ItemTitle />
                    )}
                  </Marker>
                </ItemInfo>
              </CreditCardItem>
              <CreditCardActions
                style={{
                  display:
                    creditcard.card_id === cardSelected ? "flex" : "none",
                }}
              >
                <Button
                  title="Excluir"
                  type="clear"
                  onPress={() => handleDeleteCard(creditcard)}
                  containerStyle={{ width: 150 }}
                  titleStyle={{ color: colorRed }}
                  buttonStyle={{ borderColor: colorRed }}
                />
                <Button
                  title="Tornar Padrão"
                  type="clear"
                  onPress={() => handleDefaultCard(creditcard)}
                  containerStyle={{ width: 150 }}
                  disabled={creditcard.card_id === defaultCard}
                />
              </CreditCardActions>
            </CreditCardItemGroup>
          ))}
        </CreditCardList>
      ) : (
        <Warning
          imageSource={CARD_IMAGE}
          title="Nenhum Cartão Cadastrado"
          message="Oops! Adicione um novo cartão para começar a aproveitar nossos serviços."
        ></Warning>
      )}

      <CreditCardNew>
        <Link
          text="Adicionar novo cartão"
          onPress={() =>
            navigation.navigate("CreditCard", { parent: "CreditCardManager" })
          }
          containerStyle={{ flex: 1 }}
        />
      </CreditCardNew>
    </Container>
  );
}

export default CreditCardManager;
