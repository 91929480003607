import "react-native-get-random-values";
import "react-native-url-polyfill/auto";
import { ResultLogin, ResultRefreshToken } from "../models/user";
import api from "./api";

export async function signIn(email: string, password: string): Promise<ResultLogin> {
  const response = await api.post("/user/signin", {
    email,
    password
  }
  );
  const { users } = response.data;
  return users
}

export async function signUp(email: string, password: string, name: string, phoneNumber: string): Promise<void> {
  await api.post("/user/signup", {
    email,
    name,
    phone_number: phoneNumber,
    password,
  }
  );
}

export async function confirmSignUp(email: string, code: string): Promise<void> {
  await api.post("/user/confirm", {
    email,
    code,
  }
  );
}
export async function resendSignUpCode(email: string): Promise<void> {
  await api.post("/user/resend_confirm", {
    email,
  }
  );
}

export async function forgotPassword(email: string): Promise<void> {
  await api.post("/user/forgot_password", {
    email,
  }
  );
}

export async function confirmForgotPassword(email: string, password: string, code: string): Promise<void> {
  await api.post("/user/confirm_forgot_password", {
    email,
    password,
    code
  }
  );
}

export async function refreshToken(token: string): Promise<ResultRefreshToken> {
  const response = await api.post("/user/refresh_token", {
    token,
  }
  );
  const { users } = response.data;
  return users
}