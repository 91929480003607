import styled from 'styled-components/native';
import { ActionColor } from '../../utils/colors';

export const Container = styled.View`
  flex: 1;
  margin: 0px 0px 0px 0px;
  background: #FFFFFF;
  padding: 20px 15px 0px 15px;
  overflow: hidden;
`;

export const Title = styled.Text`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: ${ActionColor};
  margin: 10px 0px 10px 0px;
  flex:1;
`;

export const LoginInfo = styled.View`
  flex: 1;
  flex-direction: column;
`;

export const AppInfo = styled.View`
  margin: 20px 0px 0px 0px;
  flex-direction: row;
  align-items: center;
`;

export const AppMessage = styled.View`
  margin: 20px 0px 40px 0px;
  flex-direction: column;
  align-items: center;
`;

export const LogoContainer = styled.View`
  align-items: center;
  margin: 30px 0;
`;

export const Logo = styled.Image`
  width: 140px;
  height: 140px;
  margin: 0;
`;

export const LoginActionGroup = styled.View`
  flex-direction: row;
  display: flex;
  margin: 0px 10px 0px 10px;
  justify-content: space-between;
  alignSelf: stretch;
  `;
  
export const Message = styled.Text`
  font-size: 16px;
  text-align: center;
  color: ${ActionColor};
  margin: 0px 0px 20px 0px;
  opacity: .4
`;