//Produção api
import axios from "axios";
import { BASE_URL_CHAT } from "../config/environment";
import userService from "./userservice";

import * as auth from "../services/auth";

const apiChat = axios.create({ baseURL: BASE_URL_CHAT, });

apiChat.interceptors.request.use(request => {
  return request
}, error => {
  console.log(error)
})

apiChat.interceptors.response.use(response => {
  return response
}, error => {
  const status = error.response ? error.response.status : null
  if (status === 401) {
    return refreshTokens().then((tokens) => {
      apiChat.defaults.headers['Authorization'] = `${tokens?.accessToken}`;
      error.config.headers['Authorization'] = `${tokens?.accessToken}`;
      return axios.request(error.config);
    })
      .catch(err => err);
  }
  return Promise.reject(error);
});

async function refreshTokens() {
  const refreshToken = await userService.getRefreshToken();
  if (refreshToken) {
    const response = await auth.refreshToken(refreshToken);
    if (response.accessToken) {
      await userService.setLogedToken(response.accessToken);
    }
    return { token: response.idToken, accessToken: response.accessToken }
  }
}

export default apiChat;