import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";

import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

import { Formik } from "formik";
import { Button, Input, useTheme } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Yup from "yup";

import Link from "../../components/Link";
import { useAuth } from "../../contexts/auth";
import { APPLOGO } from "../../image";
import { dinamicPadding } from "../../utils/dinamicPaddingInput";

import { Platform } from "react-native";
import EyeIcon from "../../components/EyeIcon";
import { AlertOS } from "../../hooks/AlertOS";
import {
  BottomContainer,
  Container,
  ContainerTextGroup,
  ForgotPasswordGroup,
  LoginInfo,
  Logo,
  LogoContainer,
  TextGroup,
  TextSignUpGroup,
} from "./styles";

interface FormValues {
  email: string;
  password: string;
}

const SignInValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("insira um email válido")
    .required("insira um email"),
  password: Yup.string()
    .min(6, "insira uma senha válida - mínimo de 6 caracteres")
    .required("insira uma senha"),
});

type RouteParamList = {
  msg: string;
};

const SignIn: React.FC = () => {
  const { theme } = useTheme();
  const navigation = useNavigation();
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const { signIn, user } = useAuth();
  const [msg, setMessage] = useState(route.params?.msg ?? "");
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [installPrompt, setInstallPrompt] = useState(null);
  const [supportInstall, setSupportInstall] = useState(false);
  const [appInstalled, setAppInstalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputEmailRef = useRef<typeof Input>(null);
  const inputPasswordRef = useRef<typeof Input>(null);
  let initialValues: FormValues = {
    email: user && user.email ? user.email : "",
    password: "",
  };
  let TITLE_ERROR = "Contate o suporte";
  let MESSAGE_ERROR =
    "Desculpe o transtorno. Entre em contato com o nosso suporte para mais informações";

  function isAppInstalled() {
    return (
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone
    );
  }

  async function handleInstallApp() {
    if (!installPrompt) {
      return;
    }
    const result = await installPrompt.prompt();
    if (result.outcome == "accepted") {
      setAppInstalled(true);
    }
    // console.log(`Install prompt was: ${result.outcome}`);
    setInstallPrompt(null);
  }
  // const notifySuccess = (message: string) => toast.success(message);
  useEffect(() => {
    //TODO : receber id da maquina, da forma implementada abaixo não funciona no iphone e android
    //TODO : HOWTO receber dados por parameter do navigation
    //const { pathname } = window.location;
    //let machineId = pathname.replace("/", "").trim();
    //if (machineId) {
    //  navigation.navigate("LoginScan", { machineId });
    //}

    if (Platform.OS == "web") {
      if ("BeforeInstallPromptEvent" in window) {
        setSupportInstall(true);
        if (isAppInstalled()) {
          setAppInstalled(true);
        } else {
          setAppInstalled(false);
        }
        window.addEventListener("beforeinstallprompt", (event) => {
          event.preventDefault();
          setInstallPrompt(event);
        });
      } else {
        setSupportInstall(false);
      }
    }

    if (msg.length != 0 || !msg === null) {
      AlertOS({
        title: msg,
        message: "Você pode acessar sua conta",
        toastType: "warn",
      });
    }
  }, []);

  async function handleSignIn(formValues: FormValues) {
    try {
      setLoading(true);
      await signIn(formValues.email, formValues.password);
    } catch (err: any) {
      setLoading(false);
      console.log("Algo deu errado, tente novamente!", err);
      if (err.response) {
        TITLE_ERROR = err.response.data.title;
        MESSAGE_ERROR = err.response.data.message;
      }
      AlertOS({
        title: TITLE_ERROR,
        message: MESSAGE_ERROR,
        toastType: "error",
      });
    }
  }

  return (
    <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
      <Container>
        <LogoContainer>
          <Logo source={APPLOGO} />
        </LogoContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={SignInValidationSchema}
          validateOnChange={false}
          onSubmit={(values) => handleSignIn(values)}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <LoginInfo>
              <Input
                leftIcon={
                  <MaterialIcons
                    name="email"
                    size={24}
                    color={theme.colors.primary}
                  />
                }
                label="Email"
                placeholder="seu@email.com"
                keyboardType="email-address"
                autoCapitalize="none"
                autoCorrect={false}
                returnKeyType="next"
                blurOnSubmit={false}
                errorMessage={errors.email}
                ref={inputEmailRef}
                onSubmitEditing={() => inputPasswordRef.current?.focus()}
                value={values.email}
                onChangeText={handleChange("email")}
                onBlur={handleBlur("email")}
                containerStyle={{
                  paddingBottom: dinamicPadding(errors.email, touched.email),
                }}
              />

              <Input
                leftIcon={
                  <MaterialIcons
                    name="lock"
                    size={24}
                    color={theme.colors.primary}
                  />
                }
                rightIcon={
                  <EyeIcon
                    onChangeVisibility={setPasswordVisibility}
                    visibility={passwordVisibility}
                  />
                }
                label="Senha"
                placeholder="******"
                secureTextEntry={passwordVisibility}
                autoCapitalize="none"
                autoCorrect={false}
                returnKeyType="send"
                errorMessage={errors.password}
                ref={inputPasswordRef}
                value={values.password}
                onChangeText={handleChange("password")}
                onBlur={handleBlur("password")}
                onSubmitEditing={() => handleSubmit()}
                containerStyle={{
                  paddingBottom: dinamicPadding(
                    errors.password,
                    touched.password
                  ),
                }}
              />

              <Button
                title="Entrar"
                loading={loading}
                onPress={() => handleSubmit()}
                style={{
                  flex: 1,
                }}
              />

              <ContainerTextGroup>
                <ForgotPasswordGroup>
                  <TextGroup>Esqueceu a Senha?</TextGroup>
                  <Link
                    text="Recupere aqui"
                    onPress={() => navigation.navigate("ForgotPassword")}
                  />
                </ForgotPasswordGroup>

                <BottomContainer>
                  {!appInstalled && Platform.OS == "web" && supportInstall && (
                    <Button
                      style={{ width: 220, alignSelf: "center" }}
                      title="Instalar App"
                      onPress={handleInstallApp}
                      type="clear"
                      icon={
                        <MaterialCommunityIcons
                          style={{ marginRight: 8, marginBottom: 4 }}
                          name="download-circle-outline"
                          size={24}
                          color={theme.colors.primary}
                        />
                      }
                    />
                  )}

                  {/* <QRCodeContainer>
                    <QRCodeButton
                      onPress={() => {
                        //console.log("Login Scan");
                        navigation.navigate("LoginScan", { machineId: "" });
                      }}
                    >
                      <MaterialCommunityIcons
                        name="qrcode-scan"
                        color={theme.colors.primary}
                        size={56}
                      />
                    </QRCodeButton>
                    <QRCodeText>Escanear máquina</QRCodeText>
                  </QRCodeContainer> */}

                  <TextSignUpGroup>
                    <TextGroup>Não possui uma conta?</TextGroup>
                    <Link
                      text="Cadastrar-se"
                      onPress={() => navigation.navigate("SignUp")}
                    />
                  </TextSignUpGroup>
                </BottomContainer>
              </ContainerTextGroup>
            </LoginInfo>
          )}
        </Formik>
      </Container>
    </KeyboardAwareScrollView>
  );
};

export default SignIn;
