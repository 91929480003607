import { RouteProp, useFocusEffect, useRoute } from "@react-navigation/native";
import * as Location from "expo-location";
import { LocationAccuracy, LocationOptions } from "expo-location";
import React, { useState } from "react";
import { Platform, RefreshControl } from "react-native";
import { LOCATION_IMAGE } from "../../image";

import Input from "../../components/Input";
// import DiscountCoupon from '../../components/DiscountCoupon';
// import Promotions from '../../components/Promotions';
// import Categories from '../../components/Categories';
import { View } from "react-native";
import { Button } from "react-native-elements";
import Loading from "../../components/Loading";
import MachineList from "../../components/MachineList";
import Warning from "../../components/Warning";
import { useAuth } from "../../contexts/auth";
import { AlertOS } from "../../hooks/AlertOS";
import { MachineIdentification } from "../../models/machine";
import { CurrentRegion, User } from "../../models/user";
import api from "../../services/api";
import { Container, Header, MachineListContainer, Title } from "./styles";

interface GetNearMachinesProps {
  lat: number;
  long: number;
}

type RouteParamList = { machines: MachineIdentification[] };

function Dashboard() {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const { user, setContextUser } = useAuth();
  const [machines, setMachines] = useState<MachineIdentification[]>([]);
  const [loading, setLoading] = useState(false);
  const [Refreshing, setRefreshing] = useState(false);
  const [daniedPermission, setDaniedPermission] = useState(false);
  const [currentRegion, setCurrentRegion] = useState<
    CurrentRegion | undefined
  >();
  const locationOptions = {
    enableHighAccuracy: true,
    timeout: 15000,
    maximumAge: 0,
  };

  async function getNearMachines({ lat, long }: GetNearMachinesProps) {
    try {
      setLoading(true);
      const response = await api.get(`/machine/near?lati=${lat}&long=${long}`);
      setRefreshing(false);
      setLoading(false);
      return response;
    } catch (err) {
      setRefreshing(false);
      setLoading(false);
      console.log("ERROR: ", err);
    }
  }

  async function loadMachines() {
    setRefreshing(true);
    setLoading(true);
    const nearMachineResponse = await getNearMachines({
      lat: 1,
      long: 1,
    });
    const machineResponse = nearMachineResponse?.data?.machines;
    if (machineResponse) {
      const newUser = { ...user } as User;
      setMachines(machineResponse);
      if (newUser) {
        newUser.nearmachines = new Map<string, MachineIdentification>();

        for (let machine of machineResponse) {
          const machineId = machine.id;
          newUser.nearmachines.set(machineId, machine);
        }
        // newUser.location = {currentRegion};
        setContextUser(newUser);
      } else {
        AlertOS({
          title: "Máquinas não encontradas",
          message: "Não encontramos máquinas na sua região",
          toastType: "info",
        });
      }
    }
    setRefreshing(false);
    setLoading(false);
  }

  function successLocationCallback(geolocation: GeolocationPosition) {
    setDaniedPermission(false);
    let { latitude, longitude } = geolocation.coords;
    setCurrentRegion({
      latitude,
      longitude,
      latitudeDelta: 0.04,
      longitudeDelta: 0.04,
    });

    //FIXME: para teste apenas comentar apos testar
    //setCurrentRegion( {latitude: -34.9019675, longitude: -8.1197023, latitudeDelta: 1000, longitudeDelta: 1000}); //-34.9019675, -8.1197023
  }

  async function handleError(error: GeolocationPositionError) {
    const { code } = error;
    switch (code) {
      case GeolocationPositionError.PERMISSION_DENIED:
        setDaniedPermission(true);
        console.error("User denied the request for Geolocation.");
        break;
      case GeolocationPositionError.POSITION_UNAVAILABLE:
        console.error("Location information is unavailable.");
        break;
      case GeolocationPositionError.TIMEOUT:
        console.error("The request to get user location timed out.");
        break;
    }
    setLoading(false);
  }

  async function loadInitialPosition() {
    if (Platform.OS === "web") {
      const { state } = await navigator.permissions.query({
        name: "geolocation",
      });
      if (state == "granted") {
        navigator.geolocation.getCurrentPosition(
          successLocationCallback,
          handleError,
          locationOptions
        );
      } else {
        AlertOS({
          title: "Autorização de Localização Negada",
          message:
            "Não podemos prosseguir sem acesso à sua localização. Ative e tente novamente.",
          toastType: "warn",
        });
        setDaniedPermission(true);
      }
    } else {
      let { granted } = await Location.requestForegroundPermissionsAsync();
      const locoptions =
        Platform.OS === "android"
          ? ({ accuracy: LocationAccuracy.High } as LocationOptions)
          : undefined; //Necessário para android funcionar
      if (granted) {
        const { coords } = await Location.getCurrentPositionAsync(locoptions);
        const { latitude, longitude } = coords;
        //Proposital delay to avoid repeat permission question
        await new Promise((resolve) => setTimeout(resolve, 3000));
        setCurrentRegion({
          latitude,
          longitude,
          latitudeDelta: 0.04,
          longitudeDelta: 0.04,
        });
      } else {
        setDaniedPermission(true);
      }
    }
  }

  // useEffect(() => {
  //   loadInitialPosition();
  // }, []);

  useFocusEffect(
    React.useCallback(() => {
      loadMachines();
    }, [])
  );

  return (
    <Container
      refreshControl={
        <RefreshControl refreshing={Refreshing} onRefresh={loadMachines} />
      }
    >
      <Input
        placeholder="Busque por item ou máquinas"
        header="false"
        icon="search"
      />
      {/* <DiscountCoupon /> */}
      {/* <Suggestions /> */}
      {/* <Offers /> */}
      <Header display={true}>
        <Title title>{"Pontos de Venda por perto"}</Title>
      </Header>
      {loading ? (
        <Loading text="Procurando máquinas nas proximidades" />
      ) : (
        <MachineListContainer>
          {daniedPermission ? (
            <View>
              <Warning
                imageSource={LOCATION_IMAGE}
                title="Autorização de Localização Negada"
                message="Não podemos prosseguir sem acesso à sua localização. 
                Por favor, habilite o serviço de localização nas configurações do seu dispositivo para continuar e busque novamente."
              ></Warning>
              <View style={{ flex: 1 }}></View>
              <Button
                title={"Buscar Novamente"}
                onPress={loadInitialPosition}
              />
            </View>
          ) : (
            <MachineList machines={machines} />
          )}
        </MachineListContainer>
      )}
    </Container>
  );
}

export default Dashboard;
