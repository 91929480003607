export const dinamicPadding = (
  error: string | undefined,
  touched: boolean | undefined
) => {
  if (error && touched) {
    return 1;
  } else if (touched) {
    return 8;
  } else if (error) {
    return 1;
  } else {
    return 8;
  }
};
