import axios, { AxiosInstance } from "axios";
import {Location} from '../models/location';

export default class CEPAPI {
    static myInstance:CEPAPI|null = null;
    static cepapi:AxiosInstance|null = null;
    
    /**
     * @returns {CEPAPI}
     */
    static getInstance() {
        if (CEPAPI.myInstance == null) {
            CEPAPI.myInstance = new CEPAPI();
            CEPAPI.cepapi = axios.create({baseURL: 'https://viacep.com.br/ws'});
        }
        return this.myInstance;
    };
    
    async getLocation (cep:string): Promise<Location|null>{
        const numcep = cep.replace('.', '').replace('.', '').replace('-', '').replace(' ', '');
        const response = await CEPAPI.cepapi?.get(`/${numcep}/json`);
        if(response?.data.logradouro){
            const result: Location = {street: response?.data.logradouro,
                                        city: response?.data.localidade, 
                                     zipCode: numcep, 
                                    district: response?.data.bairro,
                                       state: response?.data.uf,
                                     country: 'BRA'} as Location;
            return result;
        }
        return null;
    };
}


