import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";

import {
  MachineIdentification,
  ResponseMachinesByName,
} from "../../models/machine";
import api from "../../services/api";

import { Container, ResultContainer } from "./styles";

import { SearchBar } from "react-native-elements";
import MachineList from "../../components/MachineList";
import { useAuth } from "../../contexts/auth";

export default function Search() {
  const navigation = useNavigation();
  const [search, setSearch] = useState("");
  const [machines, setMachines] = useState<MachineIdentification[]>([]);
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getMachines(name: string) {
      if (false && user?.nearmachines) {
        //Busca local
        const filteredMachines = Array.from(user.nearmachines.values()).filter(
          (item) => item.name.toLowerCase().indexOf(name) != -1
        );
        setMachines(filteredMachines);
      } else {
        //Busca remota
        if (name.length >= 3) {
          setLoading(true);
          const response = await api.get<ResponseMachinesByName>(
            `/machine?s=${name}`
          );
          console.log("resulta da busca por ?:" + name);
          console.log(response);
          setMachines(response.data.machines);
          setLoading(false);
        }
      }
    }

    if (search !== "") {
      getMachines(search);
    } else {
      setMachines([]);
    }
  }, [search]);

  const search_handle = (text: string) => {
    setSearch(text);
    console.log("procurando por: " + text);
  };

  return (
    <Container>
      <SearchBar
        placeholder="Pesquise máquinas por nome..."
        onChangeText={search_handle}
        value={search}
        onCancel={() => setSearch("")}
      />
      <ResultContainer>
        <MachineList machines={machines} />
      </ResultContainer>
    </Container>
  );
}
