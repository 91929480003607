import {
  Image,
  ImageContainer,
  ItemContainer,
  ItemQuantify,
  ItemTitle,
  Price,
  Product,
  Quantity,
} from "./styles";

import InputSpinner from "react-native-input-spinner";

import { ActionColor, colorWhite } from "../../utils/colors";
import { formatCurrency } from "../../utils/formatNumber";
import { normalizeLimit } from "../../utils/normalizeLimit";
import { AlertOS } from "../../hooks/AlertOS";

interface ProductItemProps {
  id: string;
  maxQuantity: number;
  image: string;
  name: string;
  price: number;
  cartItems: any;
  updateCartItems: () => void;
}

export default function ProductItem({
  id,
  maxQuantity,
  image,
  name,
  price,
  cartItems,
  updateCartItems,
}: ProductItemProps) {
  function updateCartItem({ id, name, image, price, quantity }) {
    const productId = id;
    let updatedCart;
    const productExists = cartItems.find(
      (cartItem) => cartItem.id === productId
    );

    if (quantity === 0) {
      AlertOS({
        toastId: "remove",
        title: `${name}`,
        message: `${name} Removido`,
        toastType: "warn",
      });
      updatedCart = cartItems.filter((cartItem) => cartItem.id !== productId);
    } else {
      if (productExists) {
        if (productExists.quantity < quantity) {
          AlertOS({
            toastId: "add",
            title: `${name}`,
            message: `${name} Adicionado`,
            toastType: "success",
          });
        } else {
          AlertOS({
            toastId: "remove",
            title: `${name}`,
            message: `${name} Removido`,
            toastType: "warn",
          });
        }
        updatedCart = cartItems.map((cartItem) =>
          cartItem.id === productId ? { ...cartItem, quantity } : cartItem
        );
      } else {
        AlertOS({
          toastId: "add",
          title: `${name}`,
          message: `${name} Adicionado`,
          toastType: "success",
        });
        updatedCart = [...cartItems, { id, name, image, price, quantity }];
      }
    }
    updateCartItems(updatedCart);
  }

  return (
    <Product key={id}>
      <ImageContainer>
        <Quantity>{maxQuantity}</Quantity>
        <Image source={{ uri: image }} />
      </ImageContainer>
      <ItemTitle>{name}</ItemTitle>
      <ItemContainer>
        <ItemQuantify>
          <InputSpinner
            max={maxQuantity}
            min={0}
            step={1}
            colorMax={colorWhite}
            colorMin={colorWhite}
            rounded={false}
            value={0}
            onChange={(getQuantity: number) => {
              updateCartItem({ id, name, price, image, quantity: getQuantity });
            }}
            inputStyle={{ width: 20 }}
            style={{ width: normalizeLimit(50) }}
            colorRight={colorWhite}
            colorLeft={colorWhite}
            color={colorWhite}
            colorPress={colorWhite}
            textColor="#333333"
            fontSize={normalizeLimit(17)}
            buttonTextColor={ActionColor}
            buttonStyle={{ backgroundColor: "transparent" }}
          />
        </ItemQuantify>
        <Price>{formatCurrency(price / 100)}</Price>
      </ItemContainer>
    </Product>
  );
}
