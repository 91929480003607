import React, { useRef, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { Button, Input } from "react-native-elements";
import { Formik } from "formik";
import * as Yup from "yup";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { dinamicPadding } from "../../utils/dinamicPaddingInput";
import {Container, Title, AddressInfo, AddressInfoGroup, AddressField} from "./styles";
import { ActionColor } from "../../utils/colors";
import { isValidCEP } from "../../utils/valid";
import {Location} from "../../models/location";
import  CEPAPI  from "../../services/cepapi";
import { AlertOS } from "../../hooks/AlertOS";

interface FormValues {
  number: string;
  zipCode: string;
  complement?: string;
}

const AddressValidationSchema = Yup.object().shape({
  zipCode: 
    Yup.string()
      .test('test-number', // this is used internally by yup
      'insira um cep válido - 8 dígitos',
      value => isValidCEP(value)) // return true false based on validation
      .required("insira um cep"),
  number:
    Yup.string()
    .required("insira um número"),
});

const Address: React.FC = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState<Location>({} as Location);
  
  const inputCEPRef = useRef<Input>(null);
  const inputNumberRef = useRef<Input>(null);
  const inputComplementRef = useRef<Input>(null);
  const initialValues: FormValues = { zipCode: "", complement: "", number:"" };

  async function saveAddress(formValues: FormValues) {
    try {
      setLoading(true);
      //salvar endereço
      address.zipCode = formValues.zipCode;
      address.number = formValues.number;
      address.complement = formValues.complement ? formValues.complement: '';

      //TODO: salvar endereço
      
    } catch (error) {
      console.log(error);
      AlertOS({title: 'Salvar endereço', message: 'Algo deu errado, tente novamente!', toastType: 'error'})
    } finally{
      setLoading(false);
    }
  }
  async function getDataAdress(zipCode:string){
    console.log('consulta cep: %s',zipCode);
    const partAddress = await CEPAPI.getInstance()?.getLocation(zipCode);
    
    if(partAddress){
      const newAddress = {street: partAddress.street, district: partAddress.district, city: partAddress.city, state: partAddress.state, country: partAddress.country, zipCode: address.zipCode, number: address.number, complement: address.complement} as Location;
      setAddress(newAddress);
    }
  }
  return (
      <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
        <Container>
          <Title>Novo endereço</Title>
          <Formik
            initialValues={initialValues}
            validationSchema={AddressValidationSchema}
            validateOnChange={false}
            onSubmit={(values) => saveAddress(values)}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
            }) => (
              <AddressInfo>
                <AddressInfoGroup>                
                    <Input
                      leftIcon={
                        <MaterialIcons name="location-city" size={24} color={ActionColor} />
                      }
                      label="Cep"
                      placeholder="00.000-000"
                      maxLength={8}
                      keyboardType="numeric"
                      autoCapitalize="none"
                      autoCorrect={false}
                      returnKeyType="next"
                      blurOnSubmit={false}
                      errorMessage={errors.zipCode}
                      ref={inputCEPRef}
                      onSubmitEditing={() => inputNumberRef.current?.focus()}
                      value={values.zipCode}
                      onChangeText={handleChange("zipCode")}
                      onBlur={()=>{
                        if(values.zipCode && values.zipCode.length === 8){getDataAdress(values.zipCode);}
                        handleBlur("zipCode");}}
                      containerStyle={{
                        paddingBottom: dinamicPadding(errors.zipCode, touched.zipCode),
                        width: 170,
                        flex: 2
                      }}
                    />

                    <Input
                      leftIcon={
                        <MaterialCommunityIcons name="numeric" size={24} color={ActionColor} />
                      }
                      label="Número"
                      placeholder="0123"
                      maxLength={15}
                      keyboardType="numeric"
                      autoCapitalize="none"
                      autoCorrect={false}
                      returnKeyType="next"
                      blurOnSubmit={false}
                      errorMessage={errors.number}
                      ref={inputNumberRef}
                      onSubmitEditing={() => inputComplementRef.current?.focus()}
                      value={values.number}
                      onChangeText={handleChange("number")}
                      onBlur={handleBlur("number")}
                      containerStyle={{
                        paddingBottom: dinamicPadding(errors.number, touched.number),
                        width: 170,
                        flex: 2
                      }}

                    />
                    
                  </AddressInfoGroup>

                  <Input
                      leftIcon={
                        <MaterialIcons name="edit-location" size={24} color={ActionColor} />
                      }
                      label="Complemento"
                      placeholder="ap 2201"
                      maxLength={30}
                      keyboardType="default"
                      autoCapitalize="characters"
                      autoCorrect={false}
                      returnKeyType="next"
                      blurOnSubmit={false}
                      errorMessage={errors.complement}
                      ref={inputComplementRef}
                      onSubmitEditing={() => handleSubmit()}
                      value={values.complement}
                      onChangeText={handleChange("complement")}
                      onBlur={handleBlur("complement")}
                      containerStyle={{
                        paddingBottom: dinamicPadding(errors.complement, touched.complement),
                      }}
                    />

                  <AddressField>Rua: { address.street }</AddressField>
                  <AddressField>Bairro:{ address.district }</AddressField>
                  <AddressField>Cidade: { address.city }</AddressField>
                  <AddressField>Estado: { address.state }</AddressField>


                <Button title="Salvar" loading={loading} onPress={() => handleSubmit()} containerStyle={{alignSelf: "stretch"}}/>
              </AddressInfo>
            )}
          </Formik>
        </Container>
      </KeyboardAwareScrollView>
  );
};

export default Address;