import styled from 'styled-components/native';

export const HeaderContent = styled.View`
  height: 64px;
  flex-direction: row;
  border: 1px solid #ccc;
  align-items: center;
  background-color: #fff;
`;

export const MenuLeftButton = styled.TouchableOpacity`
  width: 28px;
  align-items: center;
  justify-content: center;
  margin-left: 10;
`;

export const HeaderText = styled.Text`
  margin-left: 10px;
  font-size: 18px;
`;