import styled from 'styled-components/native';
import { ActionColor } from '../../utils/colors';

export const FormContainer = styled.View`
  background: #FFFFFF;
  margin: 0px 0px 0px 0px;
  padding: 5px 15px 0px 15px;
  flex: 1;
`;

export const Title = styled.Text`
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  color: ${ActionColor};
  margin: 5px 0px 5px 0px;
`;

export const InputWrapper = styled.View`
`;

export const InputColumn = styled.View`
`;

export const InputColumnGroup = styled.View`
  flex: 1;
`;

export const InputWrapperGroup = styled.View`
  flex-direction: row;
  align-items: top;
  justify-content: center;
`;

export const FormActions = styled.View`
`;