import { Alert, Platform } from "react-native";
import { toast } from "react-toastify";

interface AlertOSProps {
  toastId?: string;
  title: string;
  message: string;
  toastType?: "info" | "success" | "warn" | "error";
}

export function AlertOS({
  toastId,
  title,
  message,
  toastType = "success",
}: AlertOSProps) {
  if (Platform.OS == "web") {
    require("react-toastify/dist/ReactToastify.css");
    toast[toastType](message, { toastId });
  } else {
    Alert.alert(title, message);
  }
}
