import {
  Container,
  ContainerImage,
  Message,
  Title,
  WarningImage,
} from "./styles";

interface WarningProps {
  title: string;
  message?: string;
  imageSource?: string;
  width?: number;
  height?: number;
}

export default function Warning({ imageSource, title, message }: WarningProps) {
  return (
    <Container>
      <Title>{title}</Title>
      <Message>{message}</Message>
      <ContainerImage>
        <WarningImage source={imageSource} />
      </ContainerImage>
    </Container>
  );
}
