import React, { useState, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { User } from '../../models/user';
import { IMGADDRESS } from "../../image";
import api from '../../services/api';
import
  {
    Container,
    AddressList,
    AddressItem,
    ItemInfo,
    ItemTitle,
    Marker,
    ItemImage,
    AddressItemGroup,
    AddressActions,
    AddressNew

  } from './styles';
import { useAuth } from '../../contexts/auth';
import { Location } from '../../models/location';
import { ActionColor, colorRed } from '../../utils/colors';
import Link from '../../components/Link';
import { Button, ButtonGroup, Icon } from 'react-native-elements';
import { Alert } from 'react-native';


function AddressManager() {
  const navigation = useNavigation();
  const { user } = useAuth();
  const [addresses, setAddresses] = useState<Location[]>(user?.addresses?user?.addresses:[]);  
  const [defaultAddress, setDefaultAddress] = useState<number>(user?.defaultAddress?user?.defaultAddress:0);  
  const [addressSelected, setAddressSelected] = useState<number>();
  const [loading, setLoading] = useState(false);

  const deleteAddress = (address:Location):void =>{

    //Não funciona para web
    Alert.alert("Excluir endereço",
                    `Deseja realmente excluir o endereço: ${address.street} ?`,
                    [
                      {
                        text: 'Sim, excluir o endereço',
                        onPress: () => console.log('excluir pressed')
                      },
                      {
                        text: 'Não, manter o endereço',
                        onPress: () => console.log('manter Pressed'),
                        style: 'cancel'
                      }
                    ],
                    { cancelable: true }                    
      );
  } 
  
  return (
    <Container>
      <AddressList>
        {addresses?.map((address)=>(
          <AddressItemGroup key={ address.id } style={{borderColor: address.id===addressSelected?ActionColor:'rgba(0, 0, 0, 0.1)' }}>
          <AddressItem onPress={()=>setAddressSelected(address.id)}>
            <ItemInfo>
              <ItemImage source={ IMGADDRESS} />
              <ItemTitle>{ address.street },{ address.number } </ItemTitle>
              <Marker>
                {address.id===addressSelected?<MaterialIcons name="keyboard-arrow-right" size={17} color={ActionColor} />:<ItemTitle/>}
              </Marker>
              
            </ItemInfo>
          </AddressItem>
          <AddressActions style={{display: address.id===addressSelected?'flex':'none'}}>
            <Button title="Excluir" type="clear" onPress={() =>deleteAddress(address)} containerStyle={{width: 150}} titleStyle={{color: colorRed}} buttonStyle={{borderColor: colorRed }} />
            <Button title="Tornar Padrão" type="clear" onPress={() =>{}} containerStyle={{width: 150}} disabled={address.id===defaultAddress} />
          </AddressActions>    
          </AddressItemGroup>          
        ))}
      </AddressList>
      
      <AddressNew>
        <Link text="Adicionar novo endereço" onPress={() => navigation.navigate("Address", {parent: "Address"})} containerStyle={{flex: 1}}/>      
      </AddressNew>
    </Container>
  );
};

export default AddressManager;