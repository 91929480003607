import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect } from "react";
import { Button } from "react-native-elements";

import { formatCurrency } from "../../utils/formatNumber";

import { PIX_IMAGE_ERROR } from "../../image";

import {
  AppInfo,
  BasketInfo,
  Container,
  InfoContent,
  Message,
  PixImage,
  ProductInfo,
  ProductItem,
  ProductList,
  ProductTotal,
  ProductsTitle,
  PurchaseTotal,
  Title,
} from "./styles";

import { useAuth } from "../../contexts/auth";

const PixError: React.FC = () => {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const { user } = useAuth();
  const TEN_SECONDS = 10 * 1000;
  const { total, items, machine } = route.params;
  const navigation = useNavigation();

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (user.isTotemAccount) {
        navigation.reset({ index: 0, routes: [{ name: "MachineBasket" }] });
      }
    }, TEN_SECONDS);
  });

  return (
    <Container>
      <InfoContent>
        <AppInfo>
          <PixImage source={PIX_IMAGE_ERROR} />
        </AppInfo>
        <Title>Pagamento não realizado</Title>
        <Message>
          O pagamento via Pix não foi confirmado. Que tal tentar fazer seu
          pedido novamente?
        </Message>

        <BasketInfo>
          <ProductsTitle>Resumo da compra</ProductsTitle>
          <ProductList>
            {items.map(({ id, name, quantity, price }) => (
              <ProductItem key={id}>
                <ProductInfo>
                  {name} x{quantity}
                </ProductInfo>
                <ProductTotal>
                  {" "}
                  {formatCurrency((quantity * price) / 100)}
                </ProductTotal>
              </ProductItem>
            ))}
          </ProductList>
          <PurchaseTotal>Total: {formatCurrency(total / 100)}</PurchaseTotal>
        </BasketInfo>
      </InfoContent>
      {user.isTotemAccount ? (
        <Button
          onPress={() =>
            navigation.reset({ index: 0, routes: [{ name: "MachineBasket" }] })
          }
          title="Inicio"
        />
      ) : (
        <Button
          onPress={() =>
            navigation.navigate("Payment", {
              machine,
              total,
              items,
            })
          }
          title="Formas de pagamento"
        />
      )}
    </Container>
  );
};

export default PixError;
