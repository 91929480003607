import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import PreviousRequests from "../pages/PreviousRequests";
import Requests from "../pages/Requests";
import { ActionColor } from "../utils/colors";

const tab = createMaterialTopTabNavigator();
const PurchaseTabs: React.FC = () => (
  <tab.Navigator
    initialRouteName="Purchase"
    options={{ title: "Compras" }}
    screenOptions={{
      headerShown: false,
      tabBarLabelStyle: { color: "#333", fontWeight: "bold" },
      tabBarStyle: { backgroundColor: "#FFF" },
      tabBarIndicatorStyle: { backgroundColor: ActionColor },
    }}
  >
    <tab.Screen
      name="PreviousRequests"
      component={PreviousRequests}
      options={{ title: "Anteriores" }}
    />
    <tab.Screen
      name="Requests"
      component={Requests}
      options={{ title: "Em andamento" }}
    />
  </tab.Navigator>
);

const Stack = createStackNavigator();
const PurchaseRoutes: React.FC = () => (
  <Stack.Navigator
    initialRouteName="Purchase"
    screenOptions={{ headerMode: "screen" }}
  >
    <Stack.Screen
      name="Purchase"
      component={PurchaseTabs}
      options={{
        title: "Compras",
        headerTitleAlign: "center",
        headerTitleStyle: { color: "#333" },
        headerStyle: { shadowRadius: 0, shadowOffset: { height: 0, width: 0 } },
      }}
    />
  </Stack.Navigator>
);

export default PurchaseRoutes;
