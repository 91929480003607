import React from "react";

import Categories from "../../components/Categories";
import Machines from "../../components/MachineList";

import { Container } from "./styles";

export default function Hinting() {
  return (
    <Container>
      <Categories />
      <Machines title="Pontos de Venda" display="true" />
    </Container>
  );
}
