import styled from 'styled-components/native';
import { normalizeLimit } from '../../utils/normalizeLimit';


export const Container = styled.ScrollView`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px 15px 0px 15px;
  margin: 0;
  background-color: #fff;
`;

export const BasketInfo = styled.View`
  display: flex;
  margin-top: ${normalizeLimit(20)}px;;
  flex-direction: column;
  padding: 5px 5px 0px 8px;
  border-radius: 4px;
`;

export const PurchaseTotal = styled.Text`
  flex: 1;
  font-weight: bold;
  color: #333;
  margin-left: 0px;
  padding: 10px 10px 10px 0px;
  align-items: flex-end;
  text-align: right;
  font-size: ${normalizeLimit(15)}px;
`;

export const ContainerPayment = styled.View`
  display: flex;
  flex-direction: column;
`;

export const CreditCardList = styled.View`
  flex: 1;
  margin-top: 10px;
`;

export const CardImage = styled.Image` 
  width: ${normalizeLimit(29)}px;
  height: ${normalizeLimit(21)}px;
  margin-right: 5px;
`;

export const ItemImagePix = styled.Image` 
  width: ${normalizeLimit(24)}px;
  height: ${normalizeLimit(24)}px;
  margin-right: 5px;
`;

export const ProductsTitle = styled.Text`
  font-weight: bold;
  color: #333;
  margin: 0px 15px 0px 0;
`;

export const CenteredView = styled.View`
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  background-color: gray;
`;

export const FormContainer = styled.View`
  margin: 20px;
  background-color: #fff;
  padding: 35px;
`;

export const InfoCreditCards = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px 0px 0px;
`;

export const PaymentLoading = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: ${normalizeLimit(15)}px;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 5px;
`;

export const PaymentLoadingText = styled.Text`
  font-size: ${normalizeLimit(14)}px;
  margin: 0 ${normalizeLimit(8)}px;
`

export const InputWrapper = styled.View`
  width: 100%;
`;

export const InputColumnGroup = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const CreditCardsAvaliable = styled.Text`
  font-weight: bold;
  color: #333;
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 10px;
  margin-top: 0px;
  margin-right: 0px;
  font-size: ${normalizeLimit(16)}px;
`;


export const WrapperInput = styled.View`
  margin-bottom: ${normalizeLimit(8)}px;
  margin: 0px ${normalizeLimit(10)}px;
`;

export const InputLabel = styled.Text`
  color: rgb(134, 147, 158);
  font-weight: bold;
  font-size: ${normalizeLimit(16)}px;
`;

export const ContainerInput = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${normalizeLimit(4)}px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(134, 147, 158);
`;

export const InputTextError = styled.Text`
  color: rgb(255, 86, 101);
  margin: ${normalizeLimit(5)}px;
`;

export const ProductList = styled.View`
`;

export const ProductItem = styled.View`
  display: flex;
  flex-shrink: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`;


export const ItemImage = styled.Image`
  width: ${normalizeLimit(60)}px;
  height: ${normalizeLimit(60)}px;
  border-radius: ${normalizeLimit(30)}px;
`;

export const ItemHeaderInfor = styled.View`
  color: #333;
`;

export const ItemTitle = styled.Text`
  font-weight: bold;
  font-size: ${normalizeLimit(16)}px;
  margin-left: ${normalizeLimit(8)}px;;
  color: #333;
`;


export const ItemQuantify = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 auto;
`;

export const Price = styled.Text`
  font-weight: bold;
  font-size: ${normalizeLimit(16)}px;
  color: #333;
`;