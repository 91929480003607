import { MaterialIcons } from "@expo/vector-icons";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { Button, Input } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Yup from "yup";
import { useAuth } from "../../contexts/auth";
import { AlertOS } from "../../hooks/AlertOS";
import { makeEventNotifier } from "../../hooks/CardEventListener";
import { Creditcard } from "../../models/creditcard";
import { User } from "../../models/user";
import api from "../../services/api";
import { ActionColor } from "../../utils/colors";
import { dinamicPadding } from "../../utils/dinamicPaddingInput";
import { isValidCC, isValidCVV, isValidExp } from "../../utils/valid";
import {
  FormActions,
  FormContainer,
  InputColumn,
  InputColumnGroup,
  InputWrapper,
  InputWrapperGroup,
} from "./styles";

const notifer = makeEventNotifier<{ cards: Creditcard[] }>("OnCardsChanged");

export function useCardsChangedListener(
  listener: typeof notifer.notify,
  deps: ReadonlyArray<any>
) {
  notifer.useEventListener(listener, deps);
}

interface FormValues {
  cardnumber: string;
  holdername: string;
  expiration: string;
  cvv?: number;
}

const CreditCardValidationSchema = Yup.object().shape({
  cardnumber: Yup.string()
    .test(
      "test-number", // this is used internally by yup
      "insira um número válido - 16 dígitos", //validation message
      (value) => isValidCC(value)
    ) // return true false based on validation
    .required("insira o número do cãrtao"),
  expiration: Yup.string()
    .test("test-number", "insira uma expiração válida - 4 dígitos", (value) =>
      isValidExp(value)
    )
    .required("insira uma expiração"),
  cvv: Yup.string()
    .test("test-number", "insira um código de segurança válido!", (value) =>
      isValidCVV(value)
    )
    .required("insira o código de segurança"),
  holdername: Yup.string().required("insira o nome impresso no cartão"),
});

type RouteParamList = { onSave: any; parent: string };

const CreditCard: React.FC = () => {
  const navigation = useNavigation();
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const { user, setContextUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const inputHolderNameRef = useRef<typeof Input>(null);
  const inputCardNumberRef = useRef<typeof Input>(null);
  const inputExpirationRef = useRef<typeof Input>(null);
  const inputCVVRef = useRef<typeof Input>(null);

  async function saveCard(formValues: FormValues) {
    try {
      setLoading(true);
      const cardExpirationDate =
        formValues.expiration.substring(0, 2) +
        "/20" +
        formValues.expiration.substring(2, 4);
      const response = await api.post(`/card`, {
        customer_name: user?.name,
        card_number: formValues.cardnumber,
        holder: formValues.holdername,
        expiration_date: cardExpirationDate,
        card_cvv: formValues.cvv,
        card_type: "CreditCard",
      });
      if (response.status == 201) {
        const newUser = { ...user } as User;
        const { card } = response.data;
        console.log("card:", card);
        if (user?.creditcards) {
          newUser.creditcards.push(card);
        }
        setContextUser(newUser);
        notifer.notify({ cards: newUser.creditcards });
        AlertOS({ title: "Cartão", message: "Cartão salvo com sucesso!" });
        if (route?.params?.onSave) route.params.onSave();
        if (route?.params?.parent)
          navigation.navigate(route?.params?.parent, {
            updateTime: new Date().getTime(),
          });
        else navigation.goBack();
      } else {
        throw Error("Error ao salvar cartão");
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      AlertOS({
        title: "Salvar cartão",
        message: "Algo deu errado, tente novamente!",
        toastType: "error",
      });
    }
  }
  return (
    <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
      <FormContainer>
        <Formik
          initialValues={{
            cardnumber: "",
            expiration: "",
            cvv: undefined,
            holdername: "",
          }}
          validationSchema={CreditCardValidationSchema}
          validateOnChange={false}
          onSubmit={(values) => saveCard(values)}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <>
              <InputWrapper>
                <InputColumn>
                  <Input
                    leftIcon={
                      <MaterialIcons
                        name="credit-card"
                        size={24}
                        color={ActionColor}
                      />
                    }
                    label="Número do Cartão"
                    placeholder="0000 0000 0000 0000"
                    maxLength={16}
                    keyboardType="numeric"
                    autoCapitalize="none"
                    autoCorrect={false}
                    returnKeyType="next"
                    blurOnSubmit={false}
                    errorMessage={errors.cardnumber}
                    ref={inputCardNumberRef}
                    onSubmitEditing={() => inputExpirationRef.current?.focus()}
                    value={values.cardnumber}
                    onChangeText={(text: string) => {
                      console.log("Change: ", text);

                      handleChange("cardnumber")(text);
                    }}
                    onBlur={handleBlur("cardnumber")}
                    containerStyle={{
                      paddingBottom: dinamicPadding(
                        errors.cardnumber,
                        touched.cardnumber
                      ),
                    }}
                  />
                </InputColumn>
              </InputWrapper>

              <InputWrapperGroup>
                <InputColumnGroup>
                  <Input
                    leftIcon={
                      <MaterialIcons
                        name="date-range"
                        size={24}
                        color={ActionColor}
                      />
                    }
                    label="Expiração"
                    placeholder="MMAA"
                    maxLength={4}
                    keyboardType="numeric"
                    autoCapitalize="none"
                    autoCorrect={false}
                    returnKeyType="next"
                    blurOnSubmit={false}
                    errorMessage={errors.expiration}
                    ref={inputExpirationRef}
                    onSubmitEditing={() => inputCVVRef.current?.focus()}
                    value={values.expiration}
                    onChangeText={handleChange("expiration")}
                    onBlur={handleBlur("expiration")}
                    containerStyle={{
                      paddingBottom: dinamicPadding(
                        errors.expiration,
                        touched.expiration
                      ),
                    }}
                  />
                </InputColumnGroup>

                <InputColumnGroup>
                  <Input
                    leftIcon={
                      <MaterialIcons
                        name="lock"
                        size={24}
                        color={ActionColor}
                      />
                    }
                    label="CVV"
                    placeholder="***"
                    maxLength={4}
                    keyboardType="numeric"
                    secureTextEntry={true}
                    autoCapitalize="none"
                    autoCorrect={false}
                    returnKeyType="send"
                    errorMessage={errors.cvv}
                    ref={inputCVVRef}
                    onChangeText={handleChange("cvv")}
                    onBlur={handleBlur("cvv")}
                    onSubmitEditing={() => inputHolderNameRef.current?.focus()}
                    containerStyle={{
                      paddingBottom: dinamicPadding(errors.cvv, touched.cvv),
                    }}
                  />
                </InputColumnGroup>
              </InputWrapperGroup>

              <InputWrapper>
                <InputColumn>
                  <Input
                    leftIcon={
                      <MaterialIcons
                        name="person"
                        size={24}
                        color={ActionColor}
                      />
                    }
                    label="Nome do titular"
                    placeholder="nome impresso no cartão"
                    maxLength={30}
                    keyboardType="default"
                    autoCapitalize="characters"
                    autoCorrect={false}
                    returnKeyType="next"
                    blurOnSubmit={false}
                    errorMessage={errors.holdername}
                    ref={inputHolderNameRef}
                    value={values.holdername}
                    onChangeText={handleChange("holdername")}
                    onBlur={handleBlur("holdername")}
                    containerStyle={{
                      paddingBottom: dinamicPadding(
                        errors.holdername,
                        touched.holdername
                      ),
                    }}
                  />
                </InputColumn>
              </InputWrapper>

              <FormActions>
                <Button
                  title="Salvar"
                  loading={loading}
                  onPress={() => handleSubmit()}
                  containerStyle={{ alignSelf: "stretch" }}
                />
              </FormActions>
            </>
          )}
        </Formik>
      </FormContainer>
    </KeyboardAwareScrollView>
  );
};

export default CreditCard;
