import styled from 'styled-components/native';

export const Container = styled.ScrollView.attrs({
  contentContainerStyle: props => {
    return {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  showsVerticalScrollIndicator: false
})`
  background: #F3F2F3;
  padding: 20px;
`;