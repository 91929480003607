export const IMGMACHINE = require('./machine.jpg');
export const CARD_IMAGE = require('./card.png');
export const IMGPRODUCT = require('./product.jpg');
export const APPLOGO = require('./applogo.png');
export const IMGADDRESS = require('./address.png');
export const PIX = require('./pix.png');
export const PIX_IMAGE = require('./pix-logo.png');
export const PIX_IMAGE_ERROR = require('./pix-error.png');
export const LOCATION_IMAGE = require('./location.png');
export const NO_ITENS = require('./noitens.png');
export const COUPON = require('./coupon.png');

export const creditcardsimg = (brand: string) => {
    if (brand == null || brand == undefined) {
        brand = 'noemiter'
    }

    switch (brand.toLocaleLowerCase()) {
        case 'master': { return require('./master.png'); }
        case 'mastercard': { return require('./master.png'); }
        case 'visa': { return require('./visa.png'); }
        case 'elo': { return require('./elo.png'); }
        case 'hipercard': { return require('./hipercard.png'); }
        case 'amex': { return require('./amex.png'); }
        default: { return require('./noemiter.png'); }
    }
};