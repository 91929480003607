import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Image, Text } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { MaskedTextInput } from "react-native-mask-text";
import { useAuth } from "../../contexts/auth";

import { useNavigation } from "@react-navigation/native";
import { TextInput } from "react-native";
import { AlertOS } from "../../hooks/AlertOS";
import api from "../../services/api";
import { ActionColor } from "../../utils/colors";
import { isValidCPF, isValidPhoneNumber } from "../../utils/valid";
import {
  Container,
  ImageContainer,
  InputErrorMessage,
  InputMaskedContainer,
  InputsContainer,
  Name,
} from "./styles";

interface FormValues {
  name: string;
  email: string;
  cpf: string;
  phone: string;
}

export default function EditUser() {
  const { user, setContextUser } = useAuth();
  const navigation = useNavigation();
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [cpfErrorMessage, setCpfErrorMessage] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let initialValues: FormValues = {
    name: user.name,
    email: user.email,
    cpf: user.cpf,
    phone: user.phone.substring(2),
  };

  function isValidCpf(value: string) {
    if (value.length >= 1) {
      if (!isValidCPF(value)) {
        setCpfErrorMessage("Insira um CPF válido");
        return false;
      }
    }

    setCpfErrorMessage("");
    return true;
  }

  function isValidPhoneNum(value: string) {
    if (value.length >= 1) {
      if (!isValidPhoneNumber(value)) {
        setPhoneErrorMessage("Insira um número de telefone válido");
        return false;
      }
    }

    setPhoneErrorMessage("");
    return true;
  }

  function isValidEmail(value: string) {
    if (!value) {
      setEmailErrorMessage("Campo obrigatório");
      return false;
    }

    setEmailErrorMessage("");
    return true;
  }

  function isValidName(value: string) {
    if (!value) {
      setNameErrorMessage("Campo obrigatório");
      return false;
    }

    setNameErrorMessage("");
    return true;
  }

  function handleEditUser(values: FormValues) {
    let { name, phone, email, cpf } = values;

    if (!isValidName(name)) {
      return;
    }

    if (!isValidEmail(email)) {
      return;
    }

    if (!isValidPhoneNum(phone)) {
      return;
    }

    if (!isValidCpf(cpf)) {
      return;
    }

    phone = "55" + phone;

    updateUser({ name, phone, email, cpf });
  }

  async function updateUser({ name, email, cpf, phone }: FormValues) {
    try {
      setIsLoading(true);
      const responseUser = await api.get("/user");
      if (responseUser.status == 200) {
        const { users } = responseUser.data;
        const response = await api.put("/user", {
          ...users,
          name,
          email,
          cpf,
          phone,
        });
        setContextUser({
          ...user,
          name,
          email,
          cpf,
          phone,
        });
        if (response.status === 200) {
          AlertOS({
            title: "Atualizado",
            message: "Perfil atualizado com sucesso",
          });
          navigation.goBack();
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      AlertOS({
        title: "Atualizado",
        message: "Perfil atualizado com sucesso",
        toastType: "error",
      });
    }
  }

  return (
    <Container>
      <ImageContainer>
        <Image
          style={{
            width: 100,
            height: 100,
            borderRadius: 60,
            backgroundColor: user.photo ? user.photo : ActionColor,
          }}
          source={{ uri: user.photo ? user.photo : "#" }}
          width={100}
          height={100}
        />
        <Name>{user.name}</Name>
      </ImageContainer>
      <KeyboardAwareScrollView
        contentContainerStyle={{ flex: 1, justifyContent: "space-between" }}
      >
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          onSubmit={(values) => handleEditUser(values)}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
            ...rest
          }) => (
            <>
              <InputsContainer>
                <InputMaskedContainer>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      color: "rgb(134, 147, 158)",
                    }}
                  >
                    Nome
                  </Text>
                  <TextInput
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: "rgb(134, 147, 158)",
                      fontSize: 18,
                      paddingHorizontal: 4,
                      paddingVertical: 8,
                    }}
                    placeholder="Seu nome"
                    placeholderTextColor={"rgb(134, 147, 158)"}
                    autoCapitalize="none"
                    autoCorrect={false}
                    defaultValue={values.name}
                    onChangeText={(text: string) => {
                      isValidName(text);
                      handleChange("name")(text);
                    }}
                    onSubmitEditing={() => handleSubmit()}
                    autoComplete={"name-given"}
                  />
                  <InputErrorMessage>{nameErrorMessage}</InputErrorMessage>
                </InputMaskedContainer>

                <InputMaskedContainer>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      color: "rgb(134, 147, 158)",
                    }}
                  >
                    Email
                  </Text>
                  <TextInput
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: "rgb(134, 147, 158)",
                      fontSize: 18,
                      paddingHorizontal: 4,
                      paddingVertical: 8,
                    }}
                    placeholder="example@address.com.br"
                    placeholderTextColor={"rgb(134, 147, 158)"}
                    autoCapitalize="none"
                    autoCorrect={false}
                    defaultValue={values.email}
                    onChangeText={(text) => {
                      isValidEmail(text);
                      handleChange("email")(text);
                    }}
                    onSubmitEditing={() => handleSubmit()}
                    autoComplete={"email"}
                  />
                  <InputErrorMessage>{emailErrorMessage}</InputErrorMessage>
                </InputMaskedContainer>

                <InputMaskedContainer>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      color: "rgb(134, 147, 158)",
                    }}
                  >
                    CPF
                  </Text>
                  <MaskedTextInput
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: "rgb(134, 147, 158)",
                      fontSize: 18,
                      paddingHorizontal: 4,
                      paddingVertical: 8,
                    }}
                    mask="999.999.999-99"
                    keyboardType="numeric"
                    placeholder="999.999.999-99"
                    placeholderTextColor={"rgb(134, 147, 158)"}
                    onChangeText={(text: string, rawText: string) => {
                      isValidCpf(rawText);
                      handleChange("cpf")(rawText);
                    }}
                    defaultValue={values.cpf}
                    maxLength={14}
                    autoCapitalize="none"
                    autoCorrect={false}
                    onSubmitEditing={() => handleSubmit()}
                    autoComplete={undefined}
                  />
                  <InputErrorMessage>{cpfErrorMessage}</InputErrorMessage>
                </InputMaskedContainer>

                <InputMaskedContainer>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: "700",
                      color: "rgb(134, 147, 158)",
                    }}
                  >
                    Número celular
                  </Text>
                  <MaskedTextInput
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: "rgb(134, 147, 158)",
                      fontSize: 18,
                      paddingHorizontal: 4,
                      paddingVertical: 8,
                    }}
                    mask="(99) 99999-9999"
                    keyboardType="phone-pad"
                    placeholder="(99) 9 9999-9999"
                    placeholderTextColor={"rgb(134, 147, 158)"}
                    autoCapitalize="none"
                    autoCorrect={false}
                    defaultValue={values.phone}
                    onChangeText={(text: string, rawText) => {
                      isValidPhoneNum(rawText);
                      handleChange("phone")(rawText);
                    }}
                    maxLength={15}
                    onSubmitEditing={() => handleSubmit()}
                    autoComplete={undefined}
                  />
                  <InputErrorMessage>{phoneErrorMessage}</InputErrorMessage>
                </InputMaskedContainer>
                <Button
                  loading={isLoading}
                  title="Salvar"
                  onPress={() => handleSubmit()}
                />
              </InputsContainer>
            </>
          )}
        </Formik>
      </KeyboardAwareScrollView>
    </Container>
  );
}
