import styled from 'styled-components/native';

export const Container = styled.SafeAreaView`
  margin: 0 10px;
  padding: 16px 0;
`;

export const ProfileButton = styled.TouchableOpacity`
  flex-direction: row;
  padding: 0px 0px;
  align-items: center;
  justify-content: space-around;
  width: 362px;
`;

export const Avatar = styled.Image`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #CDC
`;

export const Info = styled.View`
  width: 210px;
`;

export const Name = styled.Text`
  color: #333;
  font-size: 22px
`;

export const EditProfileBtn = styled.TouchableOpacity`

`;

export const TextBtn = styled.Text`
  font-size: 18px;
  color: #999
`;