import { useNavigation } from "@react-navigation/native";
import { BarCodeEvent, BarCodeScanner } from "expo-barcode-scanner";
import { Camera } from "expo-camera";
import React, { useEffect, useState } from "react";
import { Alert, StyleSheet, Text } from "react-native";
import { useAuth } from "../../contexts/auth";
import { Container, Info } from "./styles";

export default function LoginScan(props: any) {
  const { signIn, user, setScanMachineId } = useAuth();
  const [hasPermission, setHasPermission] = useState(false);
  const [machineId, setMachineId] = useState("");
  const [scanned, setScanned] = useState(false);
  const navigation = useNavigation();
  const [data, setData] = useState("");

  async function handleSignIn() {
    try {
      const response = await signIn("lucasvieira@luttos.com", "Generic@123");
    } catch (err: any) {
      console.log(err);
    }
  }

  async function handleBarCodeScanned2(id: any) {
    if (id.length < 36 || id.lenght > 36) return;
    setScanMachineId(id);
    await handleSignIn();
  }

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === "granted");
    })();
  }, []);

  const handleBarCodeScanned = (event: BarCodeEvent) => {
    const { type, data } = event;
    if (!scanned) {
      setScanned(true);
      console.log(
        `Bar code with type ${type} and data ${data} has been scanned!`
      );
      try {
        const { page, payload } = JSON.parse(data);
        if (page && payload) {
          //A idéia é poder usar qrcodes para promoções, máquinas e cupons no futuro
          //otexto do qrcode inicialmente terá o seguinte formato: {"page": "MachineBasket","payload": {"machineId":"1"}}
          //no futuro poderá evoluir para: {page:'MachineBasket',payload:{machineId: 1,basket:[{prod1,quant1},{prod2,quant2}]}}
          //ou {page:'Cupom', payload: {dados do novo cupom}}
          navigation.navigate(page, payload);
        }
      } catch (error) {
        console.log(`ocorreu um erro, o dado ${data} não é reconhecido!`);
        Alert.alert("Algo de errado aconteceu, por favor tente novamente.");
        setTimeout(setScanned(false), 2000); //aguarda 2 segundos para nova tentativa
      }
    }
  };

  if (hasPermission === null) {
    return <Info>Solicitando permissão para usar câmera</Info>;
  }
  if (hasPermission === false) {
    return <Info>A permissão para acessar a câmera foi negada</Info>;
  }

  return (
    <Container>
      <Camera
        style={StyleSheet.absoluteFillObject}
        onBarCodeScanned={handleBarCodeScanned}
        barCodeScannerSettings={{
          barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
        }}
      >
        <Text>Posicione o QR code abaixo e aguarde.</Text>
      </Camera>
    </Container>
  );
}
