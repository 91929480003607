export const isValidCC = (number: string|null|undefined):boolean =>
  number!=undefined && number.length === 16 && isNumber(number);

export const isValidExp = (number: string|null|undefined):boolean =>
  number!=undefined && number.length === 4 && isNumber(number);

export const isValidCVV = (number: string|null|undefined):boolean =>
  number!=undefined && number.length >= 3 && isNumber(number);

export const isValidCEP = (number: string|null|undefined):boolean =>
  number!=undefined && number.length === 8 && isNumber(number);

export const isValidPhoneNumber = (number: string|null|undefined):boolean => 
  number!=undefined && number.length === 11 && isNumber(number);

export const isValidCPF = (number: string|null|undefined):boolean =>
  number!=undefined && number.length === 11 && isNumber(number) && numcheckCPF(number);

export const isNumber = (number: string):boolean =>
    /^\d+$/.test(number);

//Função que irá testar se o CPF é valido 
export const numcheckCPF = (number: string):boolean => {
    var Soma = 0;
    // Verifica se a variável cpf é igual a "undefined", exibindo uma msg de erro
    if (number === undefined) {
      
      return false;
    }

    // Esta função retira os caracteres . e - da string do cpf, deixando apenas os números 
    var strCPF = number.replace('.', '').replace('.', '').replace('-', '');
    // Testa as sequencias que possuem todos os dígitos iguais e, se o cpf não tem 11 dígitos, retorna falso e exibe uma msg de erro
    if (strCPF === '00000000000' || strCPF === '11111111111' || strCPF === '22222222222' || strCPF === '33333333333' || 
    strCPF === '44444444444' || strCPF === '55555555555' || strCPF === '66666666666' || strCPF === '77777777777' || strCPF === '88888888888' || 
    strCPF === '99999999999' || strCPF.length !== 11) {
      
      return false;
    }

    // Os seis blocos seguintes de funções vão realizar a validação do CPF propriamente dito, conferindo se o DV bate. Caso alguma das funções não consiga verificar
    // o DV corretamente, mostrará uma mensagem de erro ao usuário e retornará falso, para que o usário posso digitar novamente um número para ser testado

    //Multiplica cada digito por numeros de 1 a 9, soma-os e multiplica-os por 10. Depois, divide o resultado encontrado por 11 para encontrar o resto
    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }

    var Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    if (Resto !== parseInt(strCPF.substring(9, 10))) {
      
      return false;
    }

    Soma = 0;
    for (let k = 1; k <= 10; k++) {
      Soma = Soma + parseInt(strCPF.substring(k - 1, k)) * (12 - k)
    }

    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    if (Resto !== parseInt(strCPF.substring(10, 11))) {
      
      return false;
    }

    return true;
  }