import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { RefreshControl, Text, View } from "react-native";
import { Button } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Yup from "yup";
import Input from "../../components/Input";
import Loading from "../../components/Loading";
import MachineSelectedView from "../../components/MachineSelectedView";
import MyModal from "../../components/MyModal";
import ProductItem from "../../components/ProductItem";
import Warning from "../../components/Warning";
import { useAuth } from "../../contexts/auth";
import { AlertOS } from "../../hooks/AlertOS";
import { NO_ITENS } from "../../image";
import { MachineIdentification } from "../../models/machine";
import { HomeRoutesStackTypes } from "../../routes/home.routes";
import api from "../../services/api";
import { colorGray, colorRed, InfoColor } from "../../utils/colors";
import { dinamicPadding } from "../../utils/dinamicPaddingInput";
import { formatCurrency } from "../../utils/formatNumber";
import {
  Container,
  InfoPurchase,
  ProductContainer,
  ProductList,
  ProductsAvaliable,
  PurchaseTotal,
} from "./styles";
import { Feather } from "@expo/vector-icons";

interface MachineBasketProps {
  machineId: string;
  machine: MachineIdentification;
}

const CodeFormValidationSchema = Yup.object().shape({
  code: Yup.string().required("Insira o código de verificação"),
});

const MachineBasket: React.FC = () => {
  const navigation = useNavigation<HomeRoutesStackTypes>();
  const route =
    useRoute<RouteProp<Record<string, MachineBasketProps>, string>>();
  const { machineId } = route.params;
  const [machine, setMachine] = useState<MachineIdentification>(
    route.params.machine
  );
  const [loading, setLoading] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [cartProducts, setCartProducts] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [isIDHActive, setIsIDHActive] = useState(false);
  const [total, setTotal] = useState(0);
  const [longPressCount, setLongPressCount] = useState(1);
  const { user } = useAuth();

  function handleIDH() {
    if (!isIDHActive) {
      window.location.href = "intent://connect/start";
    } else {
      window.location.href = "intent://connect/stop";
    }
    setIsIDHActive(!isIDHActive);
  }
  function handleLongPress() {
    setLongPressCount(longPressCount + 1);
    if (longPressCount >= 5) {
      setIsOpenModal(true);
      setLongPressCount(0);
    }
  }

  function handleSubmitCode(code) {
    setIsOpenModal(false);
    navigation.navigate("OrdersTotem", {
      code,
    });
  }

  async function getMachine() {
    try {
      setLoading(true);
      const response = await api.get(`/machine/${machineId}`);
      const { machines } = response.data;
      let productsList = machines?.products.map((item) => {
        const { id, image, name, price } = item.product;
        return { id, image, name, price, maxQuantity: item.quantity };
      });
      updateTotalItems(productsList);
      setProductsList(productsList);
      setMachine(machines);
      setLoading(false);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }
  async function saveCartItems(data) {
    try {
      setLoading(true);
      const params = {
        machine_id: machine.id,
        items: data,
      };
      api.post("/cart", { ...params });
      setLoading(false);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }

  function handlePayment() {
    if (!total) {
      AlertOS({
        title: "Selecione um produto",
        message:
          "Não existem produtos no carrinho. Selecione um produto para prosseguir",
        toastType: "info",
      });
      return;
    }
    if (user.isTotemAccount) {
      navigation.navigate("PaymentTotem", {
        machine,
        total,
        items: [...cartProducts],
      });
    } else {
      navigation.navigate("Payment", {
        machine,
        total,
        items: [...cartProducts],
      });
    }
  }

  function updateTotalItems(items) {
    const total = items.reduce((acc, cur) => {
      acc += cur.price * cur.quantity;
      return acc;
    }, 0);
    setTotal(total);
  }

  useEffect(() => {
    updateTotalItems(cartProducts);
    saveCartItems(cartProducts);
  }, [cartProducts]);

  useEffect(() => {
    getMachine();
    // let productsList = machine?.products.map((item) => {
    //   const { id, image, name, price } = item.product;
    //   return { id, image, name, price, maxQuantity: item.quantity };
    // });
    updateTotalItems(productsList);
    setProductsList(productsList);
  }, []);

  function renderItem({ item }) {
    return (
      <ProductItem
        {...item}
        cartItems={cartProducts}
        updateCartItems={setCartProducts}
      />
    );
  }

  return loading ? (
    <Loading text="Carregando..." />
  ) : (
    <Container isTotemAccount={user.isTotemAccount}>
      <MyModal visible={isOpenModal}>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "gray",
          }}
        >
          <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
            <Formik
              initialValues={{
                code: "",
              }}
              validationSchema={CodeFormValidationSchema}
              validateOnChange={true}
              onSubmit={({ code }) => {
                handleSubmitCode(code);
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <View
                  style={{
                    padding: 24,
                    backgroundColor: "#fff",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      padding: 8,
                      marginBottom: 8,
                    }}
                  >
                    <Feather name="settings" size={18} />
                    <Text style={{ marginLeft: 8 }}>Configurações</Text>
                  </View>
                  <View>
                    <View>
                      <View>
                        <Button
                          title={isIDHActive ? "Desativar IDH" : "Ativar IDH"}
                          loading={loading}
                          onPress={handleIDH}
                          buttonStyle={{
                            backgroundColor: `${
                              isIDHActive ? colorRed : InfoColor
                            }`,
                          }}
                        />
                      </View>
                      <Input
                        icon={"lock-person"}
                        onChangeText={handleChange("code")}
                        header={false}
                        placeholder="Ex:. 8e37a5fb-00a2-4db3-bc3a-88435e3c9537"
                        placeholderTextColor={colorGray}
                      />
                      <View
                        style={{
                          paddingBottom: dinamicPadding(
                            errors.code,
                            touched.code
                          ),
                        }}
                      >
                        <Text
                          style={{
                            color: colorRed,
                          }}
                        >
                          {errors.code}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        buttonStyle={{
                          paddingHorizontal: 12,
                        }}
                        title="Fechar"
                        type="clear"
                        loading={loading}
                        onPress={() => setIsOpenModal(false)}
                      />
                      <Button
                        title="Concluído"
                        loading={loading}
                        onPress={() => handleSubmit()}
                      />
                    </View>
                  </View>
                </View>
              )}
            </Formik>
          </KeyboardAwareScrollView>
        </View>
      </MyModal>
      <MachineSelectedView machine={machine} />
      {machine.products.length == 0 && !refreshing && (
        <Warning
          imageSource={NO_ITENS}
          title="Não há produtos nesta máquina"
        ></Warning>
      )}
      {refreshing ? (
        <Loading text={"Buscando produtos..."} />
      ) : (
        <ProductContainer
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={getMachine}
              enabled={true}
            />
          }
        >
          <ProductList
            numColumns={2}
            data={productsList}
            keyExtractor={(item) => item.id}
            renderItem={renderItem}
          />
        </ProductContainer>
      )}
      <InfoPurchase>
        <ProductsAvaliable>Itens a venda</ProductsAvaliable>
        <PurchaseTotal>Total: {formatCurrency(total / 100)}</PurchaseTotal>
      </InfoPurchase>
      <Button
        onLongPress={() => handleLongPress()}
        title="Ir para o carrinho"
        loading={loading}
        onPress={handlePayment}
      />
    </Container>
  );
};

export default MachineBasket;
