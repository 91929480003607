import React, { useState } from "react";
import api from "../../services/api";
import Loading from "../Loading/index";
import {
  Date,
  DateContainer,
  Info,
  Item,
  Machine,
  MachineImage,
  Menu,
  MenuButton,
  MenuText,
  Name,
  Order,
  OrderNumber,
  PurchaseList,
  Wrapper,
} from "./styles";

import { useFocusEffect, useNavigation } from "@react-navigation/native";
import moment from "moment";
import { RefreshControl } from "react-native";
import { useAuth } from "../../contexts/auth";
import { AlertOS } from "../../hooks/AlertOS";
import { IMGMACHINE, NO_ITENS } from "../../image";
import { Purchase } from "../../models/purchase";
import { formatCurrency } from "../../utils/formatNumber";
import Warning from "../Warning";

function Purchases() {
  const { user } = useAuth();
  const navigation = useNavigation();
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<Purchase[]>(
    user?.purchases ? user.purchases : []
  );

  async function fetchOrders() {
    try {
      setRefreshing(true);
      setLoading(true);
      const response = await api.get("/purchase");
      const data: Purchase[] = response.data.purchases;
      const ordersFiltered = data.filter(
        (purchase) => purchase.status != "pending"
      );
      ordersFiltered.sort((a, b) => {
        if (a.purchase_id > b.purchase_id) {
          return -1;
        } else if (a.purchase_id < b.purchase_id) {
          return 1;
        }
        return 0;
      });
      const orders = ordersFiltered.map((purchase: any) => {
        return {
          ...purchase,
          date: moment(purchase.date).format("DD/MM/YYYY H:mm"),
        };
      });
      setOrders(orders);
      setRefreshing(false);
      setLoading(false);
      return data;
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
      setRefreshing(false);
      AlertOS({
        title: "Erro ao realizar busca",
        message: "Não foi possível realizar busca dos pedidos. Tente novamente",
        toastType: "error",
      });
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      fetchOrders();
    }, [])
  );

  return loading ? (
    <Loading text="Buscando suas compras" />
  ) : (
    <>
      {orders.length == 0 ? (
        <Warning
          imageSource={NO_ITENS}
          title="Não possui compras"
          message="Que tal experimentar algo novo? É só selecionar na tela de início"
        ></Warning>
      ) : (
        <PurchaseList>
          {orders.map((item) => (
            <Item key={item.purchase_id}>
              <Wrapper
                refreshControl={
                  <RefreshControl
                    refreshing={refreshing}
                    onRefresh={fetchOrders}
                  />
                }
              >
                <DateContainer>
                  <Date>{item.date}</Date>
                </DateContainer>
                <Machine>
                  <MachineImage
                    source={item.image ? { uri: item.image } : IMGMACHINE}
                  />
                  <Info>
                    <Name>{item.description}</Name>
                    <OrderNumber>Pedido n° {item.purchase_id}</OrderNumber>
                    <Order>{formatCurrency(item.total / 100)}</Order>
                  </Info>
                </Machine>
                <Menu>
                  <MenuButton>
                    <MenuText
                      onPress={() => {
                        navigation.navigate("ChatSupport");
                      }}
                    >
                      Ajuda
                    </MenuText>
                  </MenuButton>
                  <MenuButton>
                    <MenuText>Detalhes</MenuText>
                  </MenuButton>
                </Menu>
              </Wrapper>
            </Item>
          ))}
        </PurchaseList>
      )}
    </>
  );
}

export default Purchases;
