import styled from 'styled-components/native';
import { ActionColor } from '../../utils/colors';

export const Container = styled.TouchableOpacity`
  height: auto;
  align-items: center;
  padding: 5px;
`;

export const TextLink = styled.Text`
  padding: 0px;
  margin: 5px 0px;
  color: ${ActionColor};
  text-align: center;
  font-weight: bold;
  font-size: 15px;
`;
