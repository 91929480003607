import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import { useAuth } from "../../contexts/auth";
import { ActionColor } from "../../utils/colors";

import {
  AdditionalMenu,
  AdditionalOption,
  Container,
  Description,
  Info,
  Option,
  OptionName,
  OptionsList,
  Title,
  Wrapper,
} from "./styles";

// navigation prop
function Profile() {
  const navigation = useNavigation();
  const { signOut } = useAuth();

  return (
    <Container>
      <OptionsList>
        {/* <Option onPress={() => {}}>
          <MaterialCommunityIcons
            name="bell-outline"
            size={35}
            color={ActionColor}
          />
          <Info>
            <Title>Notificações</Title>
            <Description>Minha central de notificações</Description>
          </Info>
          <MaterialIcons
            name="keyboard-arrow-right"
            color={ActionColor}
            size={20}
          />
        </Option> */}

        {/* Navegar para Wallet' */}
        {/* <Option onPress={() => navigation.navigate('Wallet')}>
          <MaterialCommunityIcons
            name="wallet-outline"
            size={35}
            color={ActionColor}
          />
          <Info>
            <Title>Carteira</Title>
            <Description>Meu saldo e QR Code</Description>
          </Info>
          <MaterialIcons
            name="keyboard-arrow-right"
            color={ActionColor}
            size={20}
          />
        </Option> */}

        {/* <Option onPress={() => {}}>
          <MaterialCommunityIcons
            name="ticket-outline"
            size={35}
            color={ActionColor}
          />
          <Info>
            <Title>Cupons</Title>
            <Description>Meus cupons de desconto</Description>
          </Info>
          <MaterialIcons
            name="keyboard-arrow-right"
            color={ActionColor}
            size={20}
          />
        </Option> */}

        <Option
          onPress={() => {
            navigation.navigate("CreditCardManager");
          }}
        >
          <MaterialCommunityIcons
            name="credit-card-outline"
            size={35}
            color={ActionColor}
          />
          <Info>
            <Title>Formas de pagamento</Title>
            <Description>Minhas formas de pagamento</Description>
          </Info>
          <MaterialIcons
            name="keyboard-arrow-right"
            color={ActionColor}
            size={20}
          />
        </Option>

        {/* <Option onPress={() => {navigation.navigate('AddressManager')}}>
          <MaterialCommunityIcons
            name="map-marker"
            size={35}
            color={ActionColor}
          />
          <Info>
            <Title>Endereços</Title>
            <Description>Meus endereços de cobrança</Description>
          </Info>
          <MaterialIcons
            name="keyboard-arrow-right"
            color={ActionColor}
            size={20}
          />
        </Option> */}

        <Option onPress={() => navigation.navigate("ChatSupport")}>
          <MaterialCommunityIcons
            name="lifebuoy"
            size={35}
            color={ActionColor}
          />
          <Info>
            <Title>Suporte</Title>
            <Description>Entre em contato via chat</Description>
          </Info>
          <MaterialIcons
            name="keyboard-arrow-right"
            color={ActionColor}
            size={20}
          />
        </Option>
      </OptionsList>
      <Option onPress={() => signOut()}>
        <MaterialCommunityIcons name="logout" size={35} color={ActionColor} />
        <Info>
          <Title>Sair da conta</Title>
          <Description>
            Será solicitado usuário e senha ao abrir o aplicativo novamente.
          </Description>
        </Info>
        <MaterialIcons
          name="keyboard-arrow-right"
          color={ActionColor}
          size={20}
        />
      </Option>

      <AdditionalMenu>
        {/* <AdditionalOption>
          <Wrapper>
            <MaterialCommunityIcons
              name="file-settings"
              size={35}
              color="#CDC"
            />
            <OptionName>Configurações</OptionName>
          </Wrapper>
          <MaterialIcons
            name="keyboard-arrow-right"
            color={ActionColor}
            size={20}
          />
        </AdditionalOption> */}
      </AdditionalMenu>
    </Container>
  );
}

export default Profile;
