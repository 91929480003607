import React from 'react';

import { ActionColor } from '../../utils/colors';
import { Container, TextLink } from './styles';

export default function Link({ text, onPress, containerStyle={} }) {

  return (
    <Container style={containerStyle}>
      <TextLink onPress={onPress}>{text}</TextLink>
    </Container>
  );
};