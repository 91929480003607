import { Platform } from 'react-native';
import { DefaultTheme } from 'styled-components';
import { ActionColor, colorRed, colorWhite } from '../utils/colors';
import { normalizeLimit } from '../utils/normalizeLimit';

//TODO extrair demais cores para variáveis de cores
const LightTheme: DefaultTheme = {
  colors: {
    primary: ActionColor,
    secondary: colorWhite,
  },
  maxdim: {
    width: '820px',
    height: '1180px'
  },
  Button: {
    titleStyle: {
      fontSize: (Platform.OS === 'ios' || Platform.OS === 'android') ? normalizeLimit(17) : 17,
      fontWeight: 'bold'
    },
    buttonStyle: {
      borderRadius: normalizeLimit(4),
      marginHorizontal: normalizeLimit(10),
    },
    containerStyle: {
      height: normalizeLimit(50)
    },
    style: { alignSelf: 'stretch' }
  },
  SearchBar: {
    inputStyle: {
      color: '#333333',
      backgroundColor: '#EEEEEE',
      fontSize: normalizeLimit(14),
      fontWeight: 'normal',
      marginHorizontal: normalizeLimit(30)
    },
    containerStyle: {
      backgroundColor: '#FFFFFF',
      borderWidth: 0,
      borderRadius: 0,
      borderHeight: 0,
      borderBottomWidth: 0,
      borderTopWidth: 0
    },
    placeholderTextColor: '#777777',
    leftIconContainerStyle: {
      backgroundColor: '#EEEEEE'
    },
    inputContainerStyle: {
      backgroundColor: '#EEEEEE'
    },
    searchIcon: { color: ActionColor, size: normalizeLimit(25) },
    cancelIcon: { color: ActionColor, size: normalizeLimit(25) }
  },

  Input: {
    errorStyle: { color: colorRed, fontSize: (Platform.OS === 'ios' || Platform.OS === 'android') ? normalizeLimit(14) : 14 },
    placeholderTextColor: "#999999",
    leftIconContainerStyle: {
      marginLeft: 0,
      marginRight: normalizeLimit(10)
    }
  },
};

export default LightTheme;