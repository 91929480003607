import React, { ReactNode } from "react";
import { Modal, View } from "react-native";

interface MyModalProps {
  visible: boolean;
  children: string | JSX.Element | JSX.Element[] | ReactNode;
}

class MyModal extends React.PureComponent<MyModalProps> {
  render() {
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.props.visible}
      >
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          {this.props.children}
        </View>
      </Modal>
    );
  }
}

export default MyModal;
