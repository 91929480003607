import styled from 'styled-components/native';
import { ActionColor } from '../../utils/colors';

export const Container = styled.View`
  margin: 0px 0px 0px 0px;
  background: #FFFFFF;
  padding: 0px 0px 0px 0px;
  flex: 1;
  flex-direction: 'column';
  justify-content: 'center';
`;

export const Title = styled.Text`
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  color: ${ActionColor};
  margin: 5px 0px 5px 0px;
`;

export const Info = styled.Text`
  font-size: 17px;
  text-align: center;
  color: ${ActionColor};
  margin: 5px 0px 5px 0px;
`;

export const CodeInfo = styled.View`
  flex-direction: column;
  align-items: center;
`;
