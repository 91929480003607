import React from 'react';
import { MaterialIcons } from '@expo/vector-icons';

import { ActionColor } from '../../utils/colors';
import { Container, TextInput } from './styles';

//props
export default function Input({header, icon, ...props }) {
  return (
    <Container header={ header }>
      <MaterialIcons
        name={icon}
        size={25}
        color={ ActionColor }
      />

      <TextInput {...props} />
    </Container>
  );
};