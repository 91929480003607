import MaterialCommunityIcons from "@expo/vector-icons/build/MaterialCommunityIcons";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Pressable, Text, View } from "react-native";
import { Button } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import { SafeAreaView } from "react-native-safe-area-context";
import Loading from "../../components/Loading";
import MyModal from "../../components/MyModal";
import { AlertOS } from "../../hooks/AlertOS";
import api from "../../services/api";
import {
  ActionColor,
  colorGray,
  colorRed,
  InfoColor,
  SuccessColor,
} from "../../utils/colors";
import { formatCurrency } from "../../utils/formatNumber";

interface TotemOrdersResponse {
  orders: TotemOrder[];
}

interface TotemOrder {
  order_id: number;
  status: "paid" | "canceled" | "pending";
  total: number;
  nsu: string;
  payment_id: string;
  authorization: string;
  date: string;
  payment_date: string;
  card_type: string;
  value: string;
  client_via: string;
}

const ORDER_STATUS = {
  paid: {
    color: SuccessColor,
    text: "Pago",
  },
  canceled: {
    color: colorRed,
    text: "Cancelado",
  },
  pending: {
    color: InfoColor,
    text: "Pendente",
  },
};

const OrdersTotem: React.FC = (props) => {
  const { code } = props?.route.params;
  const [orders, setOrders] = useState<TotemOrder[]>([]);
  const [order, setOrder] = useState<TotemOrder | null>(null);
  const [loading, setLoading] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  async function getOrders() {
    try {
      setLoading(true);
      const response = await api.get<TotemOrdersResponse>("/payment/tef");
      const orders = response.data.orders;
      orders.sort(
        (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
      );
      const ordersList = orders.map((order) => ({
        ...order,
        status: order.status.toLowerCase(),
      }));
      setOrders(ordersList);
      setLoading(false);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }
  async function sendPost(rota: string, body: any) {
    const url = `http://localhost:2001/tef/v1${rota}`;
    try {
      const response = await axios.post(url, body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const data = await response.data;
      if (data.resultado !== undefined && data.resultado.indexOf("{") !== -1)
        data.resultado = JSON.parse(data.resultado);
      return data;
    } catch (err) {
      alert(err);
      AlertOS({
        title: "Não foi possível cancelar",
        message: "Não foi possível cancelar. Entre em contato com o suporte",
        toastType: "info",
      });
    }
  }

  async function cancelTotemPayment(body: any) {
    const response = await api.post("/totem/payment/cancel", { ...body });
    const { payment } = response.data;
    return payment;
  }

  async function handleCancelOrder() {
    if (order?.status == "canceled") {
      AlertOS({
        title: "Pedido cancelado",
        message: "O pedido já cancelado",
        toastType: "info",
      });
      setCancelModalOpen(false);
      return;
    }
    if (!order) return;
    const [dateFormatter] = order.date.split(" ");
    let [day, month, fullYear] = dateFormatter.split("/");
    const year = fullYear.slice(2);
    const date = `${day}/${month}/${year}`;
    const venda = await sendPost("/adm/cancelamento", {
      valor: parseFloat(order.value).toFixed(2),
      nsu: order.nsu,
      data: date,
    });
    if (venda) {
      const response = await cancelTotemPayment({
        payment_id: order?.payment_id,
      });
      await getOrders();
    }
    setCancelModalOpen(false);
  }

  function onPressCancelOrder(order) {
    setOrder(order);
    setCancelModalOpen(true);
  }

  useEffect(() => {
    getOrders();
  }, []);
  return loading ? (
    <Loading text="Carregando..." />
  ) : (
    <SafeAreaView>
      {order && (
        <MyModal visible={cancelModalOpen}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "gray",
            }}
          >
            <View
              style={{
                padding: 24,
                backgroundColor: "#fff",
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#313131",
                  }}
                >
                  Deseja cancelar o pedido?
                </Text>
                <View
                  style={{
                    paddingVertical: 8,
                    gap: 8,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: ActionColor,
                        marginBottom: 8,
                      }}
                    >
                      Pedido #{order.order_id}
                    </Text>
                    <Text
                      style={{
                        padding: 4,
                        borderRadius: 4,
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: ORDER_STATUS[order.status].color,
                        marginBottom: 8,
                        marginLeft: 4,
                      }}
                    >
                      {ORDER_STATUS[order?.status].text}
                    </Text>
                  </View>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      color: colorGray,
                    }}
                  >
                    {moment(order.payment_date).format("DD/MM/YYYY H:mm")}
                  </Text>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      color: colorGray,
                    }}
                  >
                    {formatCurrency(order.total / 100)}
                  </Text>
                </View>
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Button
                    buttonStyle={{
                      paddingHorizontal: 40,
                    }}
                    title="Não"
                    type="outline"
                    onPress={() => setCancelModalOpen(false)}
                  />
                  <Button
                    buttonStyle={{
                      backgroundColor: colorRed,
                    }}
                    titleStyle={{
                      color: "#fff",
                    }}
                    type="solid"
                    title="Cancelar Pedido"
                    onPress={() => handleCancelOrder()}
                  />
                </View>
              </View>
            </View>
          </View>
        </MyModal>
      )}

      <ScrollView
        style={{
          padding: 24,
          backgroundColor: "#fff",
        }}
      >
        {orders.map((order) => (
          <View
            key={order.nsu}
            style={{
              flexDirection: "row",
              backgroundColor: "#fff",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottomColor: ActionColor,
              borderBottomWidth: 1,
              padding: 24,
              marginBottom: 8,
            }}
          >
            <View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: ActionColor,
                    marginBottom: 8,
                  }}
                >
                  Pedido #{order.order_id}
                </Text>
                <Text
                  style={{
                    padding: 4,
                    borderRadius: 4,
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#fff",
                    backgroundColor: ORDER_STATUS[order.status].color,
                    marginBottom: 8,
                    marginLeft: 4,
                  }}
                >
                  {ORDER_STATUS[order.status].text}
                </Text>
              </View>

              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#313131",
                  marginBottom: 4,
                }}
              >
                {formatCurrency(order.total / 100)}
              </Text>
              <Text
                style={{ fontSize: 16, fontWeight: "bold", color: "#313131" }}
              >
                {moment(order.payment_date).format("DD/MM/YYYY H:mm")}
              </Text>
            </View>
            <View>
              <Pressable onPress={() => onPressCancelOrder(order)}>
                <MaterialCommunityIcons
                  name="trash-can"
                  size={24}
                  color={colorRed}
                />
              </Pressable>
            </View>
          </View>
        ))}
      </ScrollView>
    </SafeAreaView>
  );
};

export default OrdersTotem;
