import { PressableProps } from 'react-native';
import styled from 'styled-components/native';
import { colorRed } from '../../utils/colors';
import { normalizeLimit } from '../../utils/normalizeLimit';

interface MachineContainerProps extends PressableProps {
  status: number
}

export const MachineContainer = styled.Pressable<MachineContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 15px;
  opacity: ${({ status }) => status == 3 ? .4 : 1};
`;

export const CartContainer = styled.Pressable`
  position: relative;
  margin: 0 0 0 auto;
  width: ${normalizeLimit(30)}px;
  height: ${normalizeLimit(30)}px;
`;

export const CartNumber = styled.Text`
  position: absolute;
  top: -6px;
  right: -6px;
  font-size: ${normalizeLimit(12)}px;
  background: ${colorRed};
  width: ${normalizeLimit(22)}px;
  height: ${normalizeLimit(22)}px;
  border-radius: ${normalizeLimit(22)}px;
  color: #fff;
  line-height: 22px;
  text-align: center;
  font-weight: bold;
`;

export const MachineImage = styled.Image`
  width: 60px;
  height: 60px;
  border-radius: 30px;
`;

export const MachineInfo = styled.View`
  display: flex;
  flex-shrink: 1;
  margin-left: 15px;
`;

export const MachineTitle = styled.Text`
  flex: 1;
  font-weight: bold;
  color: #333;
`;

export const MachineDescription = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  align-items: center;
  flex-shrink:1;
`;

export const MachineStar = styled.Text`
  font-size: 14px;
  color: #FF7C01;
`;

export const Categories = styled.Text`
  font-size: 14px;
  color: #999;
`;

export const Distance = styled.Text`
  font-size: 14px;
  color: #999;
`;

export const MachineAddress = styled.Text`
  flex: 1;
  flex-shrink: 1;
  font-size: 14px;
  color: #999;
`;

