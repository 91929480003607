import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import AvatarChat from "../components/AvatarChat";
import MenuLeft from "../components/Header/MenuLeft";
import Person from "../components/Header/Person";
import ChatSupport from "../pages/ChatSupport";
import CreditCardManager from "../pages/CreditCardManager";
import EditUser from "../pages/EditProfile";
import Profile from "../pages/Profile";
import Wallet from "../pages/Wallet";

const Stack = createStackNavigator();

const ProfileRoutes: React.FC = () => (
  <Stack.Navigator
    initialRouteName="Profile"
    screenOptions={{ headerMode: "screen" }}
  >
    <Stack.Screen
      name="Profile"
      component={Profile}
      options={{
        headerTitleStyle: { display: "none" },
        headerStyle: { height: 80 },
        headerShown: true,
        headerLeft: (props) => <Person />,
      }}
    />

    <Stack.Screen
      name="EditProfile"
      component={EditUser}
      options={{
        headerTitleStyle: { display: "none" },
        headerStyle: { height: 80, borderBottomWidth: 0 },
        headerShown: true,
        headerLeft: (props) => <MenuLeft />,
      }}
    />

    <Stack.Screen
      name="CreditCardManager"
      component={CreditCardManager}
      options={{
        headerTitleStyle: { display: "flex" },
        title: "Cartão de Crédito",
        headerShown: true,
        headerLeft: (props) => <MenuLeft />,
      }}
    />

    <Stack.Screen
      name="Wallet"
      component={Wallet}
      options={{
        headerBackTitleVisible: true,
        title: "Carteira",
        headerTitleAlign: "center",
        headerStyle: {
          shadowRadius: 0,
          shadowOffset: { height: 0, width: 0 },
        },
        headerLeft: (props) => <MenuLeft />,
      }}
    />
    {/* <Stack.Screen name="AddressManager" component={AddressManager} options={{ 
                                                                headerTitleStyle: {display: 'flex'}, 
                                                                title: 'Endereço',
                                                                headerTitleAlign: 'center',
                                                                headerShown: true,
                                                                headerLeft: props => <MenuLeft  />  }}/> */}

    <Stack.Screen
      name="ChatSupport"
      component={ChatSupport}
      options={{
        headerTitleStyle: { display: "flex" },
        title: "Chat",
        headerShown: false,
        headerLeft: (props) => <MenuLeft />,
      }}
    />
  </Stack.Navigator>
);

export default ProfileRoutes;
