import { useFocusEffect, useNavigation } from "@react-navigation/native";
import moment from "moment";
import React, { useState } from "react";
import Loading from "../../components/Loading";
import Warning from "../../components/Warning";
import { useAuth } from "../../contexts/auth";
import { AlertOS } from "../../hooks/AlertOS";
import { NO_ITENS } from "../../image";
import { Purchase } from "../../models/purchase";
import api from "../../services/api";
import { formatCurrency } from "../../utils/formatNumber";
import {
  Container,
  Date,
  DateContainer,
  Info,
  Item,
  Machine,
  MachineImage,
  Menu,
  MenuButton,
  MenuText,
  Name,
  Order,
  OrderNumber,
  PurchaseList,
  Wrapper,
} from "./styles";

export default function Requests() {
  const { user } = useAuth();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<Purchase[]>(
    user?.purchases ? user.purchases : []
  );

  async function getOrders() {
    try {
      setLoading(true);
      const response = await api.get("/purchase");
      const data: Purchase[] = response.data.purchases;
      const ordersFiltered = data.filter(
        (purchase) => purchase.status == "pending"
      );
      ordersFiltered.sort((a, b) => {
        if (a.purchase_id > b.purchase_id) {
          return -1;
        } else if (a.purchase_id < b.purchase_id) {
          return 1;
        }
        return 0;
      });
      const orders = ordersFiltered.map((purchase: any) => {
        return {
          ...purchase,
          date: moment(purchase.date).format("DD/MM/YYYY H:mm"),
        };
      });
      setOrders(orders);
      setLoading(false);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
      AlertOS({
        title: "Erro ao realizar busca",
        message: "Não foi possível realizar busca dos pedidos. Tente novamente",
        toastType: "error",
      });
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      getOrders();
    }, [])
  );

  return loading ? (
    <Loading text="Buscando compras em andamento" />
  ) : (
    <Container>
      {orders.length == 0 ? (
        <Warning
          imageSource={NO_ITENS}
          title="Nenhuma compra em andamento!"
          message="Que tal experimentar algo novo? É só selecionar na tela de início"
        ></Warning>
      ) : (
        <PurchaseList>
          {orders.map((item) => (
            <Item key={item.purchase_id}>
              <Wrapper>
                <DateContainer>
                  <Date>{item.date}</Date>
                </DateContainer>
                <Machine>
                  <MachineImage
                    source={item.image ? { uri: item.image } : IMGMACHINE}
                  />
                  <Info>
                    <Name>{item.description}</Name>
                    <OrderNumber>Pedido n° {item.purchase_id}</OrderNumber>
                    <Order>{formatCurrency(item.total / 100)}</Order>
                  </Info>
                </Machine>
                <Menu>
                  <MenuButton>
                    <MenuText
                      onPress={() => {
                        navigation.navigate("ChatSupport");
                      }}
                    >
                      Ajuda
                    </MenuText>
                  </MenuButton>
                  <MenuButton>
                    <MenuText>Detalhes</MenuText>
                  </MenuButton>
                </Menu>
              </Wrapper>
            </Item>
          ))}
        </PurchaseList>
      )}
    </Container>
  );
}
