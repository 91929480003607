import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Search from '../pages/Search'
import MachineBasket from "../pages/MachineBasket";
import MenuLeft from "../components/Header/MenuLeft";

const Stack = createStackNavigator();

const SearchRoutes: React.FC = () => (
  <Stack.Navigator initialRouteName="Search" screenOptions = {{headerMode: 'screen' }}>
    <Stack.Screen name="Search" component={Search} options={{title:'Pesquisa de pontos de venda'}}/>
    <Stack.Screen name="MachineBasket" component={MachineBasket} options={{headerTitleStyle: {fontSize: 13}, title:'Seus produtos estarão:',headerLeft: props => <MenuLeft  />}}/>
  </Stack.Navigator>
);

export default SearchRoutes;