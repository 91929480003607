import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import MenuLeft from "../components/Header/MenuLeft";
import { useAuth } from "../contexts/auth";
import { User } from "../models/user";
import Address from "../pages/Address";
import CreditCard from "../pages/CreditCard";
import ForgotPassword from "../pages/ForgotPassword";
import ForgotPasswordVerify from "../pages/ForgotPasswordVerify";
import LoginScan from "../pages/LoginScan";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import SignUpVerify from "../pages/SignUpVerify";
import SplashScreen from "../pages/SplashScreen";
import DashboardRoutes from "./dashboard.routes";
import TotemRoutes from "./totem.routes";
const Stack = createStackNavigator();

interface AppRoutesProps {
  user: User;
}
//Rotas para usuários autenticados
const AuthRoutes: React.FC = () => (
  <NavigationContainer>
    <Stack.Navigator
      initialRouteName="DashboardRoutes"
      screenOptions={{ headerMode: "screen", headerShown: false }}
    >
      <Stack.Screen
        name="DashboardRoutes"
        component={DashboardRoutes}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CreditCard"
        component={CreditCard}
        options={{
          headerTitleStyle: { display: "flex" },
          title: "Cartão de Crédito",
          headerShown: true,
          headerLeft: (props) => <MenuLeft />,
        }}
      />
      <Stack.Screen
        name="Address"
        component={Address}
        options={{
          headerTitleStyle: { display: "flex" },
          title: "Endereço",
          headerShown: true,
          headerLeft: (props) => <MenuLeft />,
        }}
      />
    </Stack.Navigator>
  </NavigationContainer>
);

//Rotas para usuários não autenticados
const AppRoutes: React.FC<AppRoutesProps> = ({ user }) => {
  return (
    <NavigationContainer>
      <Stack.Navigator
        initialRouteName={user && user.status == 1 ? "SignUpVerify" : "SignIn"}
      >
        <Stack.Screen
          name="SignIn"
          component={SignIn}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ForgotPassword"
          component={ForgotPassword}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ForgotPasswordVerify"
          component={ForgotPasswordVerify}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="SignUp"
          component={SignUp}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="SignUpVerify"
          component={SignUpVerify}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="LoginScan"
          component={LoginScan}
          options={{
            headerTitleStyle: { display: "flex" },
            title: "Escanear Qrcode",
            headerShown: true,
            headerLeft: (props) => <MenuLeft />,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

const Routes: React.FC = () => {
  const { signed, loading, user } = useAuth();
  //se tiver dúvidas em rotas ou autenticação
  //veja: https://www.youtube.com/watch?v=KISMYYXSIX8&t=676s
  //return <AuthRoutes />; //para teste
  //return <AppRoutes />; //para teste das rotas de autenticação
  //return <ThemeProvider theme={theme}><AppRoutes /></ThemeProvider>;
  //return <ThemeProvider theme={theme}><AuthRoutes /></ThemeProvider>;

  //return  signed ? <AuthRoutes />: <AppRoutes />;

  if (loading) {
    return <SplashScreen />;
  }
  return (
    <>
      {signed ? (
        user.isTotemAccount ? (
          <TotemRoutes
            isTotemAccount={user.isTotemAccount}
            machine={user.machine}
          />
        ) : (
          <AuthRoutes />
        )
      ) : (
        <AppRoutes user={user} />
      )}
    </>
  );
};

export default Routes;
