import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  loading: {
    width: 300,
  },

  label: {
    fontWeight: "normal",
    fontSize: 15,
    marginTop: 14,
    color: "#666",
    fontStyle: "italic",
  },
});
