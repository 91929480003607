import { generateClient } from "@aws-amplify/api";
import React, { useCallback, useEffect, useState } from "react";
import { publish } from "../../graphql/mutations";
import { ActionColor } from "../../utils/colors";

import * as subscriptions from "../../graphql/subscriptions";
import Loading from "../../components/Loading";

import axios from "axios";
import {
  Bubble,
  Composer,
  GiftedChat,
  InputToolbar,
  Send,
} from "react-native-gifted-chat";
import { useAuth } from "../../contexts/auth";
import apiChat from "../../services/apiChat";
import MaterialIcons from "@expo/vector-icons/build/MaterialIcons";
import { HeaderContent, HeaderText, MenuLeftButton } from "./styles";

const ChatSupport: React.FC = ({ navigation }) => {
  const { user, userSupport, setUserSupportData } = useAuth();
  const client = generateClient();
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<any>([]);
  const [channelId, setChannelId] = useState(userSupport.channelId);

  useEffect(() => {
    if (!channelId) return;
    const onCreateNotification = client
      .graphql({
        query: subscriptions.subscribe,
        variables: { name: channelId },
      })
      .subscribe({
        next: async ({ data }) => {
          const dataMessage = JSON.parse(data.subscribe.data);
          if (dataMessage.support_id) {
            setMessages((previousMessages) =>
              GiftedChat.append(previousMessages, [
                {
                  _id: Date.now(),
                  text: dataMessage.message,
                  createdAt: new Date(),
                  user: {
                    _id: 1,
                    name: "Suporte",
                    avatar:
                      "https://snackly-images.s3.amazonaws.com/snackly-145x145.png",
                  },
                },
              ])
            );
          }
        },
        error: (error) => console.warn(error),
      });
    return () => onCreateNotification.unsubscribe();
  }, [channelId]);

  async function getChatMessages() {
    try {
      setLoading(true);
      const response = await apiChat.get(`/chat/messages/${user.id}`);
      if (response.data.length >= 1) {
        const allMessages = response.data.map((message) => {
          return {
            _id: message.message_id,
            text: message.message,
            createdAt: new Date(message.created_at),
            user: {
              _id: message.user ? message.user.id : 1,
              name: message.user ? message.user.name : "Suporte",
              avatar:
                "https://snackly-images.s3.amazonaws.com/snackly-145x145.png",
            },
          };
        });
        setMessages((previousMessages) =>
          GiftedChat.append(previousMessages, [...allMessages.reverse()])
        );
      }
      setLoading(false);
    } catch (err) {
      console.log("ERRO: ", err);
      setLoading(false);
    }
  }

  useEffect(() => {
    navigation.getParent().setOptions({ tabBarStyle: { display: "none" } });
    getChatMessages();
    setMessages([
      {
        _id: Date.now(),
        text: "Olá! Seja bem-vindo ao nosso canal de suporte do Snackly. Em que podemos ajudar?",
        createdAt: new Date(),
        user: {
          _id: 1,
          name: "Suporte",
          avatar: "https://snackly-images.s3.amazonaws.com/snackly-145x145.png",
        },
      },
    ]);
  }, []);

  async function handleSendMessage(messages) {
    const data = JSON.stringify({
      channel_id: channelId ? channelId : "",
      user: {
        id: user.id,
        name: user.name.toLowerCase().split(" ")[0],
      },
      message: messages[0].text,
    });
    const response = await client.graphql({
      query: publish,
      variables: {
        data,
        name: channelId ? channelId : "",
      },
    });
    const { channel_id } = JSON.parse(response.data.publish.data);
    setUserSupportData({
      channelId: channel_id,
      messages: [],
    });
    setChannelId(channel_id);
  }

  const onSend = useCallback((messages = []) => {
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, messages)
    );
  }, []);
  return (
    <>
      <HeaderContent>
        <MenuLeftButton
          activeOpacity={1}
          onPress={() => {
            navigation
              .getParent()
              .setOptions({ tabBarStyle: { display: "flex" } });
            navigation.goBack();
          }}
        >
          <MaterialIcons
            name="keyboard-arrow-left"
            color={ActionColor}
            size={25}
          />
        </MenuLeftButton>
        <HeaderText>Chat - Suporte</HeaderText>
      </HeaderContent>
      {loading ? (
        <Loading text="Aguarde um momento..." />
      ) : (
        <GiftedChat
          messagesContainerStyle={{
            paddingBottom: 28,
          }}
          renderBubble={(props) => (
            <Bubble
              {...props}
              wrapperStyle={{
                right: { backgroundColor: ActionColor },
                left: { backgroundColor: "white" },
              }}
            />
          )}
          messages={messages}
          alwaysShowSend
          onSend={(messages) => {
            onSend(messages);
            handleSendMessage(messages);
            return;
          }}
          user={{
            _id: user.id,
            name: user.name,
          }}
          renderInputToolbar={(props) => (
            <InputToolbar
              {...props}
              containerStyle={{
                paddingVertical: 8,
              }}
            />
          )}
          renderComposer={(props) => (
            <Composer
              {...props}
              composerHeight={48}
              placeholder={"Digite sua mensagem..."}
            />
          )}
          renderSend={(props) => (
            <Send
              {...props}
              containerStyle={{
                height: 48,
                alignSelf: "center",
              }}
              textStyle={{ color: ActionColor }}
              label={"Enviar"}
            />
          )}
        />
      )}
    </>
  );
};

export default ChatSupport;
