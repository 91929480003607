import styled from 'styled-components/native';
import { normalizeLimit } from '../../utils/normalizeLimit';


export const Container = styled.SafeAreaView`
  margin: 0px 0px 0px 0px;
  background: #FFFFFF;
  padding: 5px 15px 0px 15px;
  flex: 1;
`;

export const CreditCardList = styled.ScrollView`
  margin-top: 10px;
  flex-direction: column;
`;

export const CreditCardActions = styled.View`
margin: 0px 0px 0px 0px;
padding: 0px 0px 0px 0px;
flex-direction: row;
justify-content: space-between;
`;

export const CreditCardNew = styled.View`
margin: 0px 0px 0px 0px;
padding: 0px 0px 0px 0px;
flex-direction: row;
align-items: center;
flex: 1;
`;

export const CreditCardItemGroup = styled.View`
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;  
`;

export const CreditCardItem = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-right: 0px;
`;

export const ItemImage = styled.Image`
  width: ${normalizeLimit(29)}px;
  height: ${normalizeLimit(21)}px;
  margin-right: 5px;
`;

export const ItemInfo = styled.View`
  flex: 1;
  flex-direction: row;
  margin-left: 15px
`;

export const ItemTitle = styled.Text`
  font-weight: bold;
  color: #333;
  margin-left: 15px;
  margin-right: 15px
`;

export const Marker = styled.View`
  flex: 1;
  margin-top: 0px;
  margin-left: 0px;
  padding: 0px 10px 0px 0px;
  align-items: flex-end;
`;