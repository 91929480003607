import styled from 'styled-components/native';
import { ActionColor } from '../../utils/colors';

export const Container = styled.ScrollView`
  flex: 1;
  background: #FFF;
`;

//export const BackButton = styled.TouchableOpacity``;

export const Panel = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 40px 0
`;

export const Finances = styled.View``;

export const Money = styled.View`
  display: flex;
  flex-direction: row;
  align-items: baseline
`;

export const Title = styled.Text`
  font-size: 18px;
  color: #999
`;

export const Value = styled.Text`
  margin-top: 10px;
  font-size: 25px
`;

export const QrCode = styled.View`
  align-items: center
`;

export const Options = styled.ScrollView`
  background: #FFFFFF;
  padding: 10px 0px 0px 0px;
  margin: 10px 20px 5px 20px;
  flex-direction: column;
`;

export const Option = styled.TouchableOpacity`
  flex-direction: row;
  background: #FFF;
  margin: 5px;
  border-radius: 4px;
  align-items: flex-start;
  margin: 10px 20px 5px 20px;
`;

export const Message = styled.Text`
  margin: 10px 0px 0px 10px;
  font-size: 17px;
  color: ${ActionColor}
  flex: 1;
  `;