import styled from 'styled-components/native';
import { colorBlack, colorGray } from '../../utils/colors';
import { normalizeLimit } from '../../utils/normalizeLimit';

export const Container = styled.View`
  flex: 1;
  margin: 0;
  justify-content: space-between;
  background: #FFFFFF;
  padding: 20px 15px 0px 15px;
  color: ${colorGray};
`;

export const Title = styled.Text`
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  margin: 18px 0;
  color: ${colorBlack};
`;

export const InfoContent = styled.View`
  margin-top: 10%;
`;

export const AppInfo = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Message = styled.Text`
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  color: ${colorGray};
`;

export const BasketInfo = styled.View`
  display: flex;
  flex-direction: column;
  padding: 5px 5px 0px 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const ProductsTitle = styled.Text`
  font-weight: bold;
  color: #333;
  margin: 0px 15px 0px 0;
`;

export const ProductList = styled.ScrollView`
  margin-top: 2px;
  height: ${normalizeLimit(80)}px;
  flex-direction: column;
`;

export const ProductItem = styled.View`
  display: flex;
  flex-direction: row;
  margin: 2px 2px 0px 0px;
`;

export const ProductInfo = styled.Text`
  font-size: ${normalizeLimit(12)}px;
  color: #333333;
  margin-left: 0;
  margin-right: 2px
`;

export const ProductTotal = styled.Text`
  margin-left: 0px;
  font-size: ${normalizeLimit(12)}px;
  font-weight: bold;
  color: #333333;
`;

export const PurchaseTotal = styled.Text`
  flex: 1;
  font-weight: bold;
  color: #333;
  margin-left: 0px;
  padding: 10px 10px 10px 0px;
  align-items: flex-end;
  text-align: right;
  font-size: ${normalizeLimit(15)}px;
`;
