import { MaterialIcons } from "@expo/vector-icons";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { Formik, FormikHelpers } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Input } from "react-native-elements";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import * as Yup from "yup";

import Link from "../../components/Link";
import { useAuth } from "../../contexts/auth";
import { AlertOS } from "../../hooks/AlertOS";
import { APPLOGO } from "../../image";
import { ActionColor } from "../../utils/colors";
import { dinamicPadding } from "../../utils/dinamicPaddingInput";
import { Container, LoginInfo, Logo, LogoContainer } from "./styles";

type RouteParamList = {
  email: string;
};

interface FormValues {
  code: string;
}

const SignUpVerifyValidationSchema = Yup.object().shape({
  code: Yup.number()
    .min(6, "insira um codigo válido - mínimo 6 caracteres")
    .required("insira um codigo de verificação"),
});

const SignUpVerify: React.FC = () => {
  const route = useRoute<RouteProp<Record<string, RouteParamList>, string>>();
  const { user, confirmSignUp, resendSignUpCode, setContextUser } = useAuth();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const inputCodeRef = useRef<typeof Input>(null);
  const initialValues: FormValues = {
    code: "",
  };
  let TITLE_ERROR = "Contate o suporte";
  let MESSAGE_ERROR =
    "Desculpe o transtorno. Entre em contato com o nosso suporte para mais informações";

  useEffect(() => {
    AlertOS({
      title: "Código de Verificação",
      message: "Verifique o código que enviamos para o seu email",
      toastType: "info",
    });
  }, []);

  async function handleSignUpVerifyRegister(
    formValues: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) {
    try {
      setLoading(true);
      const { email } = route.params;
      const { code } = formValues;
      await confirmSignUp(email, code);
      AlertOS({
        title: "Cadastro realizado",
        message: "Registro efetuado com sucesso!",
      });
      setLoading(false);
      formikHelpers.resetForm();
      navigation.navigate("SignIn", { msg: "Cadastro realizado com sucesso!" });
    } catch (err: any) {
      setLoading(false);
      console.log("Algo deu errado, tente novamente!", err);
      if (err.response) {
        TITLE_ERROR = err.response.data.title;
        MESSAGE_ERROR = err.response.data.message;
      }
      AlertOS({
        title: TITLE_ERROR,
        message: MESSAGE_ERROR,
        toastType: "error",
      });
    }
  }

  async function handleSignUpVerifyResend() {
    try {
      setLoading(true);
      const { email } = route.params;
      await resendSignUpCode(email);
      AlertOS({
        title: "Verifique seu email para prosseguir.",
        message: "Código de verificação reenviado com sucesso!",
      });
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log("Algo deu errado, tente novamente!", err);
      if (err.response) {
        TITLE_ERROR = err.response.data.title;
        MESSAGE_ERROR = err.response.data.message;
      }
      AlertOS({
        title: TITLE_ERROR,
        message: MESSAGE_ERROR,
        toastType: "error",
      });
    }
  }

  return (
    <KeyboardAwareScrollView contentContainerStyle={{ flex: 1 }}>
      <Container>
        <LogoContainer>
          <Logo source={APPLOGO} />
        </LogoContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={SignUpVerifyValidationSchema}
          validateOnChange={false}
          onSubmit={(values, formikHelpers) =>
            handleSignUpVerifyRegister(values, formikHelpers)
          }
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <LoginInfo>
              <Input
                leftIcon={
                  <MaterialIcons name="lock" size={24} color={ActionColor} />
                }
                leftIconContainerStyle={{
                  marginLeft: 0,
                  marginRight: 10,
                }}
                label="Código de verificação"
                keyboardType="numeric"
                maxLength={6}
                autoCapitalize="none"
                autoCorrect={false}
                returnKeyType="next"
                ref={inputCodeRef}
                blurOnSubmit={false}
                onSubmitEditing={() => handleSubmit()}
                errorMessage={errors.code}
                value={values.code}
                onChangeText={handleChange("code")}
                onBlur={handleBlur("code")}
                containerStyle={{
                  paddingBottom: dinamicPadding(errors.code, touched.code),
                }}
              />

              <Button
                title="Verificar"
                loading={loading}
                onPress={() => handleSubmit()}
              />

              <Button
                title="Voltar"
                loading={loading}
                onPress={() => navigation.navigate("SignUp")}
                type="outline"
                containerStyle={{
                  marginVertical: 1,
                }}
              />

              <Link
                text="Reenviar código"
                onPress={() => handleSignUpVerifyResend()}
                containerStyle={{
                  flexDirection: "row",
                }}
              />
            </LoginInfo>
          )}
        </Formik>
      </Container>
    </KeyboardAwareScrollView>
  );
};

export default SignUpVerify;
