import React from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { ActionColor } from "../../utils/colors";

import styles from "./styles";

interface loadingProps {
  text: string
}

export default function Loading({ text }: loadingProps ) {
  return (
    <View style={styles.container}>
      <View>
        <ActivityIndicator size="small" color={ActionColor} />
        <Text style={styles.label}>{text ? text: "Carregando..."}</Text>
      </View>
    </View>
  );
};